<template>
  <div class="p-8">
    <div class="flex gap-2">
      <text-input v-model="searchTerm" class="w-full" type="search" placeholder="Enter license number here..." />
      <loading-button class="btn-indigo" :loading="loading" @click="find"><span class="fas fa-search fa-sm mr-2" /> Search</loading-button>
    </div>
    <div v-if="license" class="py-4">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold border-b">
          <th class="py-2 pl-2">Name</th>
          <th class="py-2">Number</th>
          <th class="py-2">Discipline</th>
          <th class="py-2">Type</th>
        </tr>
        <tr class="hover:bg-gray-100 focus-within:bg-gray-100 text-sm">
          <td class="border-t">
            <Link class="flex items-center py-1 pl-2" :href="`/licenses/${license.id}`" tabindex="-1">
              <img v-if="license.user.photo" class="block mr-1 w-5 h-5 rounded-full" :src="license.user.photo" />
              <span>{{ license.user.first_name }} {{ license.user.last_name }}</span>
            </Link>
          </td>

          <td class="border-t">
            <Link class="flex items-center py-1" :href="`/licenses/${license.id}`" tabindex="-1">
              <span class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-green-600 rounded-full">{{ license.number }}</span>
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center py-1" :href="`/licenses/${license.id}`" tabindex="-1">
              <span class="bg-gray-200 p-1 rounded-sm">{{ license.form.discipline.name }}</span>
            </Link>
          </td>

          <td class="border-t">
            <Link class="flex items-center py-1" :href="`/licenses/${license.id}`" tabindex="-1">
              <span class="bg-gray-200 p-1 rounded-sm">{{ license.form.business_type == '0' ? 'Business' : 'Individual' }}</span>
            </Link>
          </td>

          <td class="w-px border-t">
            <Link class="flex items-center px-2" :href="`/licenses/${license.id}`" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </Link>
          </td>
        </tr>
      </table>
    </div>

    <div v-if="!license && !loading" class="text-center py-4 text-lg font-bold">No record found.</div>
    <div class="pt-4 border-t border-gray-100 flex justify-end">
      <loading-button v-if="license" class="btn-indigo" :loading="loading" @click="associate">Associate</loading-button>
    </div>
  </div>
</template>

<script>
import TextInput from '../TextInput.vue'
import LoadingButton from '@/Shared/LoadingButton'
import axios from 'axios'

export default {
  components: {
    TextInput,
    LoadingButton,
  },

  data() {
    return {
      license: null,
      loading: false,
      searchTerm: null,
    }
  },

  methods: {
    async find() {
      this.loading = true

      try {
        let response = await axios.get('/licenses/find?number=' + this.searchTerm)

        this.license = response.data
      } catch (error) {
        console.error(error)
      }

      this.loading = false
    },

    async associate() {
      this.loading = true

      try {
        await axios.post('/associations/', { license_id: this.license.id })
        this.$emit('associated')
      } catch (error) {
        console.error(error)
      }

      this.loading = false
    },
  },
}
</script>