<template>
  <div class="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200 self-start">
    <header class="px-5 py-4 border-b border-slate-100">
      <h2 class="font-semibold text-slate-800">Recent Activity</h2>
    </header>
    <div class="p-3">
      <table class="w-full whitespace-nowrap mt-2 text-sm">
        <tbody>
          <tr class="hover:bg-gray-100 focus-within:bg-gray-100 border-b">
            <td class="py-1">
              <p>Admin <span class="font-bold">added</span> a new user.</p>
            </td>
            <td class="w-px">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </td>
          </tr>
          <tr class="hover:bg-gray-100 focus-within:bg-gray-100 border-b">
            <td class="border-b py-1">
              <p>Mike <span class="font-bold">updated</span> the status of an application.</p>
            </td>
            <td class="w-px">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </td>
          </tr>
          <tr class="hover:bg-gray-100 focus-within:bg-gray-100 border-b">
            <td class="border-b py-1">
              <p>Carlos <span class="font-bold">updated</span> an application.</p>
            </td>
            <td class="w-px">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </td>
          </tr>
          <tr class="hover:bg-gray-100 focus-within:bg-gray-100 border-b">
            <td class="border-b py-1">
              <p>Youth Institute <span class="font-bold">added</span> a new role and permission.</p>
            </td>
            <td class="w-px">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </td>
          </tr>

          <tr class="hover:bg-gray-100 focus-within:bg-gray-100 border-b">
            <td class="border-b py-1">
              <p>Lisa <span class="font-bold">submitted</span> an application.</p>
            </td>
            <td class="w-px">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </td>
          </tr>
          <tr class="hover:bg-gray-100 focus-within:bg-gray-100 border-b">
            <td class="border-b py-1">
              <p>Bob <span class="font-bold">submitted</span> an application.</p>
            </td>
            <td class="w-px">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </td>
          </tr>
          <tr class="hover:bg-gray-100 focus-within:bg-gray-100 border-b">
            <td class="border-b py-1">
              <p>Stephen <span class="font-bold">approved</span> an application.</p>
            </td>
            <td class="w-px">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </td>
          </tr>
          <tr class="focus-within:bg-indigo-500 text-white text-center bg-indigo-800 cursor-pointer">
            <td class="py-1" colspan="9">
              <Link href="/activity-logs"><p>See all activity logs...</p></Link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3'

export default {
  name: 'ActivityLog',
  components: { Link },
}
</script>
