<template>
  <Line :data="chartData" :options="chartOptions" />
</template>

<script>
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

export default {
  name: 'App',
  components: {
    Line,
  },
  data() {
    return {
      chartData: {
        labels: ['Draft', 'Submitted', 'Paid', 'Pending Review', 'License Issued', 'Declined'],
        datasets: [
          {
            label: 'Applications Based on Status',
            backgroundColor: '#f87979',
            data: [40, 20, 12, 39, 10, 40],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    }
  },
}
</script>
