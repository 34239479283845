<template>
  <div>
    <Head title="Dashboard" />

    <!-- Content area -->
    <div class="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
      <main>
        <div class="w-full max-w-9xl mx-auto">
          <!-- Cards -->
          <div v-if="['Super Admin', 'Admin'].includes($page.props.auth?.user?.role)" class="grid grid-cols-12 gap-6">
            <!-- Line chart (Acme Plus) -->
            <DashboardCard01 class="sm:!col-span-4 xl:!col-span-3" />
            <!-- Line chart (Acme Advanced) -->
            <DashboardCard02 class="sm:!col-span-4 xl:!col-span-3" />
            <!-- Line chart (Acme Professional) -->
            <ExpiredLicensesCard class="sm:!col-span-4 xl:!col-span-3" />
            <!-- Line chart (Acme Professional) -->
            <DashboardCard03 class="sm:!col-span-4 xl:!col-span-3" />
            <!-- Bar chart (Direct vs Indirect) -->
            <DashboardCard04 />
            <!-- Card (Recent Activity) -->
            <ActivityLog />
          </div>

          <div v-if="userCan('search applications')">
            <h3 class="text-xl font-semibold mt-4">Occupational and Professional License Search</h3>
            <license-search />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import LicenseSearch from './LicenseSearch.vue'
import DashboardCard01 from '@/Shared/DashboardCards/DashboardCard01.vue'
import DashboardCard02 from '@/Shared/DashboardCards/DashboardCard02.vue'
import DashboardCard03 from '@/Shared/DashboardCards/DashboardCard03.vue'
import DashboardCard04 from '@/Shared/DashboardCards/DashboardCard04.vue'
import ExpiredLicensesCard from '@/Shared/DashboardCards/ExpiredLicensesCard.vue'
import ActivityLog from '@/Shared/DashboardCards/ActivityLogCard.vue'
import { userCan } from '@/utils/Utils'

export default {
  components: {
    Head,
    LicenseSearch,
    DashboardCard01,
    DashboardCard02,
    DashboardCard03,
    DashboardCard04,
    ExpiredLicensesCard,
    ActivityLog,
  },

  layout: Layout,

  methods: {
    userCan,
  },
}
</script>
