<template>
  <div>
    <Head title="All Licenses" />
    <div class="flex justify-between items-center pb-2">
      <h1 class="text-2xl font-bold">All Licenses</h1>
      <div class="flex gap-2">
        <Link class="btn-indigo" @click="clearFilters"><span class="fas fa-filter fa-sm" /> Clear all Filters</Link>
        <loading-button v-if="userCan('export data')" class="btn-indigo" @click="exportToCsv"><span class="fas fa-file-export fa-sm mr-2" /> Export to Excel</loading-button>
        <loading-button v-if="userCan('export data')" class="btn-indigo" @click="exportToCsv"><span class="fas fa-file-export fa-sm mr-2" /> Export to CSV</loading-button>
        <Link class="btn-indigo" href="/licenses/create">
          <span><span class="fas fa-plus fa-sm mr-2" />Apply for a License</span>
        </Link>
      </div>
    </div>

    <!-- <div class="flex justify-between items-center">
      <h1 class="mb-8 text-2xl font-bold">All Licenses</h1>
      <div class="flex items-center justify-between mb-6">
        <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
          <label class="block text-gray-700">Status:</label>
          <select class="form-select mt-1 w-full">
            <option :value="null" />
            <option value="user">Active</option>
            <option value="owner">Expired</option>
          </select>
        </search-filter>
        <Link class="btn-indigo" href="/licenses/create">
          <span><span class="fas fa-plus fa-sm mr-2" />Apply for a License</span>
        </Link>
        <loading-button v-if="userCan('export data')" class="btn-indigo ml-2" @click="exportToCsv"><span class="fas fa-file-export fa-sm mr-2" /> Export to CSV</loading-button>
      </div>
    </div> -->

    <div class="grid grid-cols-6 gap-4 items-center pt-3">
      <div class="flex flex-col w-full">
        <div class="flex justify-between">
          <label class="block text-gray-800">Type</label>
          <Link class="text-orange-500 mr-2 text-sm" @click="form.type = null">Clear</Link>
        </div>

        <select v-model="form.type" class="form-select mt-1 w-full">
          <option value="all">All</option>
          <option value="individual">Individual</option>
          <option value="business">Business</option>
        </select>
      </div>

      <div class="flex flex-col w-full">
        <div class="flex justify-between">
          <label class="block text-gray-800">Discipline</label>
          <Link class="text-orange-500 mr-2 text-sm" @click="form.discipline = null">Clear</Link>
        </div>
        <select v-model="form.discipline" class="form-select mt-1 w-full">
          <option value="all">All</option>
          <option v-for="discipline in disciplines" :key="discipline.id" :value="discipline.id">{{ discipline.name }}</option>
        </select>
      </div>

      <div class="flex flex-col w-full">
        <label class="block text-gray-800 mb-1">License Number</label>
        <text-input v-model="form.number" type="search" class="w-full" />
      </div>

      <div class="flex flex-col w-full">
        <label class="block text-gray-800 mb-1">Licensee Name</label>
        <text-input v-model="form.licensee_name" type="search" class="w-full" />
      </div>

      <div class="flex flex-col w-full">
        <div class="flex justify-between">
          <label class="block text-gray-800 mb-1">Licensee DOB</label>
          <Link class="text-orange-500 mr-2 text-sm" @click="form.licensee_dob = null">Clear</Link>
        </div>
        <text-input v-model="form.licensee_dob" type="date" />
      </div>

      <div class="flex flex-col w-full">
        <div class="flex justify-between">
          <label class="block text-gray-800 mb-1">Issue Date</label>
          <Link class="text-orange-500 mr-2 text-sm" @click="form.issue_date = null">Clear</Link>
        </div>
        <text-input v-model="form.issue_date" type="date" />
      </div>
    </div>

    <div class="bg-white rounded-md shadow overflow-x-auto mt-4">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold border-b">
          <th class="p-4">Name</th>
          <th class="p-4">Date of Birth</th>
          <th class="p-4">Number</th>
          <th class="p-4">Discipline</th>
          <th class="p-4">Type</th>
          <th class="p-4">Status</th>
          <th class="p-4">Issued Date</th>
          <th class="p-4">Expiry Date</th>
        </tr>
        <tr v-for="license in licenses.data" :key="license.id" class="hover:bg-gray-100 focus-within:bg-gray-100 text-sm">
          <td class="border-t">
            <Link class="flex items-center px-4 py-2" :href="`/licenses/${license.id}`" tabindex="-1">
              <img v-if="license.user.photo" class="block mr-1 w-5 h-5 rounded-full" :src="license.user.photo" />
              {{ license.user.first_name }} {{ license.user.last_name }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-4 py-2 focus:text-indigo-500" :href="`/licenses/${license.id}`">
              {{ license.user.dob }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-4 py-2" :href="`/licenses/${license.id}`" tabindex="-1">
              <span class="bg-green-500 p-1 rounded-sm text-white font-semibold text-sm">{{ license.number }} </span>
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-4 py-2" :href="`/licenses/${license.id}`" tabindex="-1">
              {{ license.form.discipline.name }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-4 py-2 focus:text-indigo-500" :href="`/licenses/${license.id}`">
              {{ license.form.business_type == '0' ? 'Business' : 'Individual' }}
            </Link>
          </td>

          <td class="border-t">
            <Link class="flex items-center px-4 py-2" :href="`/licenses/${license.id}`" tabindex="-1">
              <span v-if="isExpired(license) == 'Expired'" class="bg-orange-500 p-1 rounded-sm text-white font-semibold text-sm">{{ isExpired(license) }} </span>
              <span v-if="isExpired(license) == 'Active'" class="bg-green-500 p-1 rounded-sm text-white font-semibold text-sm">{{ isExpired(license) }} </span>
            </Link>
          </td>

          <td class="border-t">
            <Link class="flex items-center px-4 py-2 focus:text-indigo-500" :href="`/licenses/${license.id}`">
              {{ license.approved_at }}
            </Link>
          </td>

          <td class="border-t">
            <Link class="flex items-center px-4 py-2 focus:text-indigo-500" :href="`/licenses/${license.id}`">
              {{ license.expiryDate }}
            </Link>
          </td>

          <td class="w-px border-t">
            <Link class="flex items-center px-4" :href="`/licenses/${license.id}`" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </Link>
          </td>
        </tr>
        <tr v-if="licenses.data.length === 0">
          <td class="px-4 py-2 border-t" colspan="4">No licenses found.</td>
        </tr>
      </table>
    </div>
    <pagination class="mt-6" :links="licenses.links" />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Icon from '@/Shared/Icon'
import Pagination from '@/Shared/Pagination'
import LoadingButton from '@/Shared/LoadingButton'
import { userCan } from '@/utils/Utils'
import TextInput from '@/Shared/TextInput'

export default {
  components: {
    Head,
    Link,
    Icon,
    Pagination,
    LoadingButton,
    TextInput,
  },
  layout: Layout,
  props: {
    licenses: Object,
    filters: Object,
    disciplines: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        type: this.filters.type,
        discipline: this.filters.discipline,
        number: this.filters.number,
        licensee_name: this.filters.licensee_name,
        issue_date: this.filters.issue_date,
        licensee_dob: this.filters.licensee_dob,
      },
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function () {
        this.$inertia.get('/licenses', this.form, { preserveState: true })
      }, 150),
    },
  },
  methods: {
    userCan,
    reset() {
      this.form = mapValues(this.form, () => null)
    },

    clearFilters() {
      this.form = mapValues(this.form, () => null)
    },

    exportToCsv() {
      window.location.href = '/licenses/download'
    },

    isExpired(license) {
      if (license.approved_at) {
        const approvedDate = new Date(license.approved_at)
        const today = new Date()
        const diffTime = Math.abs(today - approvedDate)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        if (diffDays > 60) {
          return 'Expired'
        } else {
          return 'Active'
        }
      }
    },
  },
}
</script>
