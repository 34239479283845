<template>
  <div>
    <modal :show="showModal">
      <div class="p-4 text-lg font-semibold border-b border-gray-100">Send Notification</div>
      <div class="p-4">
        <select-input class="pb-8 pr-6 w-full lg:w-1/2" label="To">
          <option>All</option>
          <option>Staff Members</option>
          <option>Portal Users</option>
        </select-input>
        <textarea-input class="pb-8 pr-6 w-full" label="Message" />
      </div>

      <div class="p-4 bg-gray-100 flex justify-end gap-2">
        <button class="btn bg-green-600 hover:bg-green-700 rounded px-4 py-2 text-white font-semibold" @click="showModal = false">Cancel</button>
        <loading-button class="btn-indigo" @click="sendNotification">Send</loading-button>
      </div>
    </modal>

    <Head title="System Notifications" />

    <div class="flex justify-between items-center">
      <h1 class="mb-8 text-2xl font-bold">Notification Center</h1>
      <div class="flex items-center justify-between mb-6">
        <search-filter class="mr-4 w-full max-w-md" @reset="reset">
          <label class="block text-gray-700">Role:</label>
          <select v-model="form.role" class="form-select mt-1 w-full">
            <option :value="null" />
            <option value="user">User</option>
            <option value="owner">Owner</option>
          </select>
          <label class="block mt-4 text-gray-700">Trashed:</label>
          <select class="form-select mt-1 w-full">
            <option :value="null" />
            <option value="with">With Trashed</option>
            <option value="only">Only Trashed</option>
          </select>
        </search-filter>
        <loading-button class="btn-indigo" @click="showModal = true">
          <span><span class="fas fa-bell fa-sm mr-2" />Send Notification</span>
        </loading-button>
      </div>
    </div>

    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-medium border-b text-sm">
          <th class="p-4">Sender</th>
          <th class="p-4">Description</th>
          <th class="p-4">Created At</th>
        </tr>
        <tr v-for="notification in notifications" :key="notification.id" class="border-b">
          <td class="p-4">{{ notification.sender }}</td>
          <td class="p-4">{{ notification.description }}</td>
          <td class="m-2 border-t inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-green-500 rounded-full">{{ notification.created_at }}</td>
        </tr>

        <tr v-if="notifications.length == 0">
          <td class="m-2 border-t inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-yellow-500 rounded-full" colspan="4">Your inbox is empty.</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import Modal from '@/Shared/Modal'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import TextareaInput from '@/Shared/TextareaInput'
import SearchFilter from '@/Shared/SearchFilter'

export default {
  components: {
    Head,
    Modal,
    LoadingButton,
    SelectInput,
    TextareaInput,
    SearchFilter,
  },

  layout: Layout,

  data() {
    return {
      showModal: false,
      loading: false,
      form: this.$inertia.form(),
      notifications: [
        {
          id: 1,
          sender: 'Admin',
          description: 'Your application has been approved.',
          created_at: '2021-05-01 12:00:00',
        },
        {
          id: 2,
          sender: 'Admin',
          description: 'Your appication is under review.',
          created_at: '2021-05-01 12:00:00',
        },
        {
          id: 3,
          sender: 'Admin',
          description: 'Your License with number BEC10000 is expiring soon.',
          created_at: '2021-05-01 12:00:00',
        },
      ],
    }
  },

  methods: {
    sendNotification() {
      this.form.patch('/notifications/notify', {
        onSuccess: () => (this.showModal = false),
      })
    },
  },
}
</script>
