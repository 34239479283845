<template>
  <div>
    <Head title="Create Workflow" />
    <h1 class="mb-8 text-2xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/license-types">Workflow</Link>
      <span class="text-indigo-400 font-medium">/</span> Create
    </h1>

    <div class="pb-10">
      <div class="w-full py-6">
        <div class="flex">
          <div class="w-1/4">
            <div class="relative mb-2">
              <div class="w-10 h-10 mx-auto border-2 border-gray-200 rounded-full text-lg text-white flex items-center" :class="getStateClass(1)">
                <span class="text-center text-white w-full"> 1 </span>
              </div>
            </div>

            <div class="text-xs text-center md:text-base">License Type Information</div>
          </div>

          <div class="w-1/4">
            <div class="relative mb-2">
              <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
                <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                  <div class="w-0 py-1 rounded" :class="getStateClass(1)" style="width: 100%" />
                </div>
              </div>

              <div class="w-10 h-10 mx-auto border-2 border-gray-200 rounded-full text-lg flex items-center" :class="getStateClass(2)">
                <span class="text-center w-full"> 2 </span>
              </div>
            </div>

            <div class="text-xs text-center md:text-base">Configure Questionnaire</div>
          </div>

          <div class="w-1/4">
            <div class="relative mb-2">
              <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
                <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                  <div class="w-0 py-1 rounded" :class="getStateClass(2)" style="width: 100%" />
                </div>
              </div>

              <div class="w-10 h-10 mx-auto border-2 border-gray-200 rounded-full text-lg text-white flex items-center" :class="getStateClass(3)">
                <span class="text-center w-full"> 3 </span>
              </div>
            </div>

            <div class="text-xs text-center md:text-base">Assign Staff</div>
          </div>

          <div class="w-1/4">
            <div class="relative mb-2">
              <div class="absolute flex align-center items-center align-middle content-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
                <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                  <div class="w-0 py-1 rounded" :class="getStateClass(3)" style="width: 100%" />
                </div>
              </div>

              <div class="w-10 h-10 mx-auto border-2 border-gray-200 rounded-full text-lg text-white flex items-center" :class="currentState == 4 ? 'bg-green-600' : 'bg-white'">
                <span class="text-center w-full" :class="currentState == 4 ? 'text-white' : 'text-gray-600'"> <span class="fas fa-check" /> </span>
              </div>
            </div>

            <div class="text-xs text-center md:text-base">Finished</div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-2 h-full w-full flex justify-center">
      <div v-if="currentState == 1" class="col-span-2 bg-white rounded-md shadow overflow-hidden" style="width: 60%">
        <h3 class="p-4 font-semibold text-medium bg-gray-200">Workflow Details</h3>
        <hr />
        <form @submit.prevent="store">
          <div class="grid grid-cols-2 flex-wrap -mb-8 -mr-6 px-4 pb-2 pt-4">
            <text-input v-model="form.name" :error="form.errors.name" class="pb-8 pr-6 w-full" label="License Name" />
            <select-input v-model="form.discipline_id" :error="form.errors.discipline_id" class="pb-8 pr-6 w-full" label="Discipline">
              <option v-for="discipline in disciplines" :key="discipline.id" :value="discipline.id">{{ discipline.name }}</option>
            </select-input>
            <textarea-input v-model="form.description" :error="form.errors.description" class="pb-8 pr-6 w-full" label="Description" />
            <div class="flex flex-col">
              <label class="mb-4">Business Type:</label>
              <div>
                <label for="business"> <input id="business" v-model="form.business_type" type="radio" value="0" name="business_type" /> Business </label>
                <label for="individual"> <input id="individual" v-model="form.business_type" type="radio" value="1" name="business_type" /> Individual </label>
              </div>
              <span class="text-red-500">{{ form.errors.business_type }}</span>
            </div>
          </div>
          <!-- <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
            <loading-button :loading="form.processing" class="btn-indigo" type="submit">Create Workflow</loading-button>
          </div> -->
        </form>
      </div>

      <div v-if="currentState == 2">
        <div class="col-span-1 max-w-3xl bg-white rounded-md shadow overflow-hidden">
          <h3 class="p-4 font-semibold text-medium bg-gray-200">Workflow Questions</h3>
          <hr />
          <table class="w-full whitespace-nowrap">
            <tr class="text-left font-normal text-sm">
              <th class="px-4 py-2">Add</th>
              <th class="px-4 py-2">Question</th>
              <th class="px-4 py-2">Type</th>
            </tr>

            <tr v-for="question in questions" :key="question.id" class="hover:bg-gray-100 focus-within:bg-gray-100 text-sm">
              <td class="border-t">
                <div class="px-4 py-1">
                  <input type="checkbox" class="h-5 w-5" :name="question.id" :value="question.id" v-model="form.questions" />
                </div>
              </td>
              <td class="border-t">
                <Link class="flex items-center px-4 py-1 focus:text-indigo-500" :href="`/questions/${question.id}/edit`">
                  {{ question.title }}
                </Link>
              </td>
              <td class="border-t">
                <Link class="flex items-center px-4 py-1" :href="`/questions/${question.id}/edit`" tabindex="-1">
                  <div v-if="question.title">
                    {{ questionType(question.type) }}
                  </div>
                </Link>
              </td>
            </tr>

            <tr v-if="questions.length === 0">
              <td class="px-4 py-2 border-t text-sm" colspan="4">No questions found.</td>
            </tr>
          </table>
        </div>
        <div v-if="form.errors.questions" class="text-red-400 mt-4">At least one question must be selected</div>
      </div>
      <div v-if="currentState == 3" class="col-span-1 max-w-3xl bg-white rounded-md shadow overflow-hidden h-full">
        <h3 class="p-4 font-semibold text-medium bg-gray-200">Workflow Users</h3>
        <hr />
        <table class="w-full whitespace-nowrap">
          <tr class="text-left font-normal text-sm">
            <th class="px-4 py-2">Add</th>
            <th class="px-4 py-2">User</th>
          </tr>

          <tr v-for="user in users" :key="user.id" class="hover:bg-gray-100 focus-within:bg-gray-100 text-sm">
            <td class="border-t">
              <div class="px-4 py-1">
                <input type="checkbox" class="h-5 w-5" :name="user.id" :value="user.id" v-model="form.users" />
              </div>
            </td>
            <td class="border-t">
              <Link class="flex items-center px-4 py-1 focus:text-indigo-500" :href="`/users/${user.id}/edit`"> {{ user.first_name }} {{ user.last_name }} </Link>
            </td>
          </tr>

          <tr v-if="users.length === 0">
            <td class="px-4 py-2 border-t text-sm" colspan="4">No users found.</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="flex justify-center w-full pt-t-2">
      <div class="flex items-center justify-end py-4 pr-2" @click="previous()">
        <button class="btn-indigo">Back</button>
      </div>
      <div v-if="currentState < 3" class="flex items-center justify-end py-4" @click="next()">
        <button class="btn-indigo">Next</button>
      </div>
      <div v-if="currentState == 3" class="flex items-center justify-end py-4">
        <loading-button :loading="form.processing" :disabled="form.users.length == 0" class="btn-indigo" type="submit" @click="store">Create Workflow</loading-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import TextareaInput from '@/Shared/TextareaInput'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import { types } from './types'
import axios from 'axios'

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    TextInput,
    TextareaInput,
    SelectInput,
  },

  layout: Layout,
  props: {
    disciplines: Array,
    questions: Array,
    users: Array,
  },
  remember: 'form',

  data() {
    return {
      form: this.$inertia.form({
        name: '',
        description: '',
        discipline_id: '',
        business_type: '',
        questions: [],
        step: 1,
        users: [],
      }),
      types: types,
      addedQuestion: [],
      currentState: 1,
    }
  },
  methods: {
    getStateClass(state) {
      if (state == this.currentState) {
        return 'bg-yellow-500 text-white font-bold'
      } else if (state > this.currentState) {
        return 'bg-white text-gray-600'
      } else if (state < this.currentState) {
        return 'bg-green-600 text-white'
      } else {
        return 'bg-white text-gray-600'
      }
    },

    next() {
      this.form.step = this.currentState
      axios
        .post('/license-types?step=' + this.currentState, this.form)
        .then((res) => {
          this.currentState = Math.min(this.currentState + 1, 4)
        })
        .catch((error) => this.store())
    },

    previous() {
      this.currentState = Math.max(this.currentState - 1, 1)
    },

    store() {
      this.form.step = this.currentState
      this.form.post('/license-types')
    },

    questionType(id) {
      return this.types.find((t) => t.id == id).value
    },
  },
}
</script>
