<template>
  <div v-if="show" class="fixed top-[70px] right-5 z-40 max-w-[360px] space-y-2">
    <Toast2 v-if="$page.props.flash.success" type="success" :open="true">
      {{ $page.props.flash.success }}
    </Toast2>
    <Toast2 v-if="$page.props.flash.error" type="error" :open="true">
      {{ $page.props.flash.error }}
    </Toast2>
    <template v-if="Object.keys($page.props.errors).length > 0" v-for="(message) in Object.values($page.props.errors)"
      :key="`toast-${message}`">
      <Toast2 type="error" :open="true">
        {{ message }}
      </Toast2>
    </template>
  </div>
</template>

<script>
import Toast2 from '@/components/Toast2.vue';

export default {
  data() {
    return {
      show: true,
    };
  },
  watch: {
    "$page.props.flash": {
      handler() {
        this.show = true;
        setTimeout(() => {
          this.$page.props.flash.error = null
          this.show = false
        }, 5000);
      },
      deep: true,
    },
  },
  components: { Toast2 }
}
</script>
