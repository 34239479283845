<template>
  <div class="flex h-screen overflow-hidden">
    <!-- Sidebar -->
    <!-- <Sidebar :sidebarOpen="sidebarOpen" @close-sidebar="sidebarOpen = false" /> -->
    <main-menu :auth-user="auth" :sidebarOpen="sidebarOpen" @close-sidebar="sidebarOpen = false" />

    <!-- Content area -->
    <div class="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
      <!-- Site header -->
      <Header :sidebarOpen="sidebarOpen" @toggle-sidebar="sidebarOpen = !sidebarOpen" />

      <main>
        <div class="px-4 py-6 w-full max-w-9xl mx-auto relative gap-4">
          <flash-messages />
          <div :class="{ 'col-span-8': !showActions(), 'col-span-8 md:col-span-6': showActions() }">
            <slot />
          </div>

          <!-- <div v-if="showActions()" class="col-span-2 hidden md:block">
            <div class="bg-white p-2">
              <h3 class="font-semibold text-medium">Quick Links/Info</h3>

              <div class="grid grid-cols-2 items-center mt-4">
                <Link class="flex cursor-pointer items-center" :href="`/users/${auth.user.id}/edit`">
                  <i class="fas fa-user fa-sm" />
                  <h3 class="font-semibold ml-1 text-gray-500 hover:text-gray-700 focus:text-indigo-500 text-sm">Edit My Profile</h3>
                </Link>
                <Link class="flex cursor-pointer items-center" href="#">
                  <i class="fas fa-print fa-sm" />
                  <h3 class="font-semibold ml-1 text-gray-500 hover:text-gray-700 focus:text-indigo-500 text-sm">Print Monthly Report</h3>
                </Link>
              </div>
              <div class="grid grid-cols-2 items-center mt-4">
                <Link class="flex cursor-pointer items-center" href="#">
                  <i class="fas fa-file fa-sm" />
                  <h3 class="font-semibold ml-1 text-gray-500 hover:text-gray-700 focus:text-indigo-500 text-sm">Uploaded Documents</h3>
                </Link>
                <Link class="flex cursor-pointer items-center" href="/users/create">
                  <i class="fas fa-user-plus fa-sm" />
                  <h3 class="font-semibold ml-1 text-gray-500 hover:text-gray-700 focus:text-indigo-500 text-sm">Add Staff Member</h3>
                </Link>
              </div>
              <div class="grid grid-cols-2 justify-between items-center mt-4">
                <Link class="flex cursor-pointer items-center" href="#">
                  <i class="fas fa-sticky-note fa-sm" />
                  <h3 class="font-semibold ml-1 text-gray-500 hover:text-gray-700 focus:text-indigo-500 text-sm">My Notes</h3>
                </Link>
                <Link class="flex cursor-pointer items-center" href="/roles/create">
                  <i class="fas fa-plus fa-sm" />
                  <h3 class="font-semibold ml-1 text-gray-500 hover:text-gray-700 focus:text-indigo-500 text-sm">Add Role</h3>
                </Link>
              </div>
            </div>

            <div class="bg-white p-2 mt-7">
              <h3 class="font-semibold text-medium"><span class="fas fa-chart-bar fa-sm mr-2" />System Stats</h3>

              <div class="grid grid-cols-2 gap-2 mt-2">
                <div class="flex flex-col">
                  <Link class="flex justify-between cursor-pointer items-center mt-1" href="#">
                    <h3 class="font-semibold ml-1 text-gray-500 hover:text-gray-700 focus:text-indigo-500 text-sm">Total Applications</h3>
                    <span class="bg-indigo-800 text-white font-semibold rounded-sm px-2 py-1 text-xs">7</span>
                  </Link>
                  <Link class="flex justify-between cursor-pointer items-center mt-1" href="#">
                    <h3 class="font-semibold ml-1 text-gray-500 hover:text-gray-700 focus:text-indigo-500 text-sm">Declined Applications</h3>
                    <span class="bg-indigo-800 text-white font-semibold rounded-sm px-2 py-1 text-xs">2</span>
                  </Link>

                  <Link class="flex justify-between cursor-pointer items-center mt-1" href="#">
                    <h3 class="font-semibold ml-1 text-gray-500 hover:text-gray-700 focus:text-indigo-500 text-sm">Total Licenses</h3>
                    <span class="bg-indigo-800 text-white font-semibold rounded-sm px-2 py-1 text-xs">9</span>
                  </Link>
                  <Link class="flex justify-between cursor-pointer items-center mt-1" href="#">
                    <h3 class="font-semibold ml-1 text-gray-500 hover:text-gray-700 focus:text-indigo-500 text-sm">Suspended Licenses</h3>
                    <span class="bg-indigo-800 text-white font-semibold rounded-sm px-2 py-1 text-xs">1</span>
                  </Link>
                </div>

                <div class="flex flex-col">
                  <Link class="flex justify-between cursor-pointer items-center mt-1" href="#">
                    <h3 class="font-semibold ml-1 text-gray-500 hover:text-gray-700 focus:text-indigo-500 text-sm">Expired Licenses</h3>
                    <span class="bg-indigo-800 text-white font-semibold rounded-sm px-2 py-1 text-xs">3</span>
                  </Link>
                  <Link class="flex justify-between cursor-pointer items-center mt-1" href="#">
                    <h3 class="font-semibold ml-1 text-gray-500 hover:text-gray-700 focus:text-indigo-500 text-sm">Issued Licenses</h3>
                    <span class="bg-indigo-800 text-white font-semibold rounded-sm px-2 py-1 text-xs">5</span>
                  </Link>

                  <Link class="flex justify-between cursor-pointer items-center mt-1" href="#">
                    <h3 class="font-semibold ml-1 text-gray-500 hover:text-gray-700 focus:text-indigo-500 text-sm">Staff Members</h3>
                    <span class="bg-indigo-800 text-white font-semibold rounded-sm px-2 py-1 text-xs">5</span>
                  </Link>
                  <Link class="flex justify-between cursor-pointer items-center mt-1" href="#">
                    <h3 class="font-semibold ml-1 text-gray-500 hover:text-gray-700 focus:text-indigo-500 text-sm">Portal Users</h3>
                    <span class="bg-indigo-800 text-white font-semibold rounded-sm px-2 py-1 text-xs">3</span>
                  </Link>
                </div>
              </div>
            </div>

            <div class="bg-white p-2 mt-7">
              <h3 class="font-semibold text-medium"><span class="fas fa-bell fa-sm mr-2" />Important Notifications</h3>

              <table class="w-full whitespace-nowrap mt-2 text-sm">
                <tbody>
                  <tr class="hover:bg-gray-100 focus-within:bg-gray-100 border-b">
                    <td class="py-1">
                      <p>30 licenses will be expiring soon.</p>
                    </td>
                    <td class="w-px cursor-pointer">
                      <span class="fas fa-refresh fa-sm" />
                    </td>
                  </tr>
                  <tr class="hover:bg-gray-100 focus-within:bg-gray-100 border-b">
                    <td class="border-b py-1">
                      <p>8 applications are waiting for your action.</p>
                    </td>
                    <td class="w-px">
                      <icon name="cheveron-right" class="block w-6 h-6" />
                    </td>
                  </tr>
                  <tr class="hover:bg-gray-100 focus-within:bg-gray-100 border-b">
                    <td class="border-b py-1">
                      <p>Please complete your profile.</p>
                    </td>
                    <td class="w-px">
                      <span class="fas fa-user fa-sm" />
                    </td>
                  </tr>

                  <tr class="hover:bg-gray-100 focus-within:bg-gray-100 border-b">
                    <td class="border-b py-1">
                      <p>40 applicants registered this week.</p>
                    </td>
                    <td class="w-px">
                      <span class="fas fa-user fa-sm" />
                    </td>
                  </tr>

                  <tr class="focus-within:bg-indigo-500 text-white text-center bg-indigo-800 cursor-pointer">
                    <td class="py-1" colspan="9">
                      <p>Notification center...</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> -->
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Logo from '@/Shared/Logo'
import Dropdown from '@/Shared/Dropdown'
import MainMenu from '@/Shared/MainMenu'
import FlashMessages from '@/Shared/FlashMessages'
import Bell from '@/Shared/Bell'
import Header from './Header.vue'
import { ref } from 'vue'
import Toast2 from '@/components/Toast2.vue'
import { userCan } from '@/utils/Utils'

export default {
  components: {
    Dropdown,
    FlashMessages,
    Icon,
    Link,
    Logo,
    MainMenu,
    Bell,
    Header,
    Toast2,
  },
  props: {
    auth: Object,
  },

  setup() {
    const sidebarOpen = ref(false)

    return {
      sidebarOpen,
    }
  },
  data() {
    return {
      url: null,
    }
  },
  mounted() {
    //get me the last 2 part of the url
    this.url = this.$page.url.substring(1)
    console.log(this.url)
  },
  watch: {
    '$page.url': {
      handler() {
        let url = this.$page.url.substring(1)
        this.$data.url = url
      },
      deep: true,
    },
  },
  methods: {
    isUrl(...urls) {
      let currentUrl = this.$page.url.substring(1)
      if (urls[0] === '') {
        return currentUrl === ''
      }
      return urls.filter((url) => currentUrl.startsWith(url)).length
    },
    showActions() {
      if (!userCan('Super Admin', this.$page.props)) return false
      return !['form.create', 'form.edit', 'licenses.create', 'licenses.edit'].some((u) => this.route().current(u)) || this.url == '' || this.url == '/'
    },
  },
}
</script>
