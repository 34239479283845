<template>
  <div>
    <div class="mt-4">
      <ul class="flex">
        <li v-for="(stage, index) in stages" :key="stage.name" class="border border-gray-300 justify-center flex items-center pl-4 w-full relative font-semibold" :class="getClasses(index)">
          {{ stage.name }}
          <span v-if="!['Declined'].includes(stage.name)" class="fa-sm pl-3" :class="getIcon(stage.name)" />
        </li>
      </ul>
    </div>

    <div v-if="license.status == 'Declined'" role="alert" class="pt-4">
      <div class="flex border border-red-400 rounded-lg bg-red-100 px-4 py-2 text-red-700">
        <p class="font-semibold mr-2">Decline Reason:</p>
        <p>{{ license.rejection_reason }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['license'],

  data() {
    return {}
  },

  computed: {
    stages() {
      let stages = [{ name: 'Draft' }, { name: 'Pay Application' }, { name: 'Submit Application' }, { name: 'Pending Review' }, { name: 'License Issued' }]

      if (this.license.status == 'Declined') {
        stages.pop()
        stages.push({ name: 'Declined' })
      }

      return stages
    },
  },

  methods: {
    getClasses(index) {
      if (this.license.status == 'Declined' && index == this.stages.length - 1) {
        return 'bg-red-100 text-red-700'
      }

      const stageIndex = this.stages.findIndex((st) => st.name == this.license.status)

      if (index == stageIndex + 1) {
        return 'bg-yellow-100 text-yellow-700'
      }

      if (index > stageIndex) {
        return 'text-gray-500'
      }

      return 'bg-green-100 text-green-700'
    },

    isStageDone(index) {
      const stageIndex = this.stages.findIndex((st) => st.name == this.license.status)

      return index <= stageIndex
    },

    getIcon(stage) {
      var stages = {
        Draft: 'fas fa-file',
        'Pay Application': 'fas fa-credit-card',
        'Submit Application': 'fas fa-paper-plane',
        'Pending Review': 'fas fa-magnifying-glass',
        'License Issued': 'fas fa-check',
        Declined: 'fas fa-ban',
      }
      return stages[stage]
    },
  },
}
</script>
<style>
li {
  height: 50px;
}
/* li:not(:last-child)::after {
  content: '';
  position: absolute;
  display: block;
  height: 58px;
  width: 58px;
  border-right: 1px solid rgb(203 213 225 / var(--tw-border-opacity));
  border-top: 1px solid rgb(203 213 225 / var(--tw-border-opacity));
  right: 10px;
  transform: rotate(45deg);
} */
</style>
