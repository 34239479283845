
<template>
  <div class="my-4 border-b-2 pb-4">
    <div class="flex pb-3">
      <div class="flex-1">
      </div>
      <template v-for="(step, index) in totalSteps" ::key="index">
        <div class="flex-1 relative">
          <div
            class="w-10 h-10 mx-auto relative rounded-full text-lg text-white flex items-center cursor-pointer hover:bg-indigo-600 group"
            :class="{ 'bg-indigo-500': step <= currentStep, 'bg-white border-2 border-gray-200': !(step <= currentStep) }">
            <FontAwesomeIcon v-if="removable" icon="fas fa-times"
              class="text-gray-900 text-xs absolute top-[-13px] right-[-15px] p-2 hidden group-hover:inline cursor-pointer hover:text-indigo-500"
              @click.self="onRemove(index)" />
            <span @click="handleClick(step)" class="text-center w-full group-hover:text-white"
              :class="{ 'text-white': step <= currentStep, 'text-gray-900': !(step <= currentStep) }">
              {{ step }}</span>
          </div>
        </div>
        <div v-if="step != totalSteps" class="w-1/6 align-center items-center align-middle content-center flex">
          <div class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
            <div class=" text-xs leading-none py-1 text-center text-gray-900 rounded "
              :class="{ 'bg-indigo-500': step + 1 <= currentStep, 'bg-green-200': !(step + 1 <= currentStep) }">
            </div>
          </div>
        </div>
      </template>
      <div class="flex-1 flex justify-center items-center">
        <FontAwesomeIcon icon="fas fa-plus" v-if="addable"
          class="text-gray-900 text-xs rounded-t p-2 group-hover:inline cursor-pointer hover:text-indigo-700"
          @click="onAdd" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { onMounted, ref, computed, onBeforeMount } from 'vue';
onMounted(() => {
  console.log(props);
})
const props = defineProps({
  totalSteps: {
    type: Number,
    required: true
  },
  currentStep: {
    type: Number,
    required: true
  },
  addable: {
    type: Boolean,
    default: false
  },
  removable: {
    type: Boolean,
    default: false
  },
  handleClick: {
    type: Function
  },
  onAdd: {
    type: Function,
    default: () => { }
  },
  onRemove: {
    type: Function,
    default: () => { }
  }
});
computed(props.currentStep)

</script>
