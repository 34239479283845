<template>
  <div>
    <modal :show="showModal">
      <div class="p-4 text-lg font-semibold border-b border-gray-100">Are you sure want to decline this application?</div>

      <div class="p-4">
        <textarea-input v-model="form.reason" class="w-full" :error="form.errors.reason" label="Reason" />
      </div>

      <div class="flex justify-end gap-2 p-4 bg-gray-100">
        <button class="btn-indigo" @click="showModal = false">Cancel</button>
        <button class="btn-indigo" @click="decline">Decline</button>
      </div>
    </modal>

    <modal :show="feedbackModal">
      <div class="p-4 text-lg font-semibold border-b border-gray-100">Feedback to Applicant</div>

      <div class="p-4">
        <textarea-input v-model="form.feedback" class="w-full" :error="form.errors.feedback" placeholder="Please enter your feedback here..." />
      </div>

      <div class="flex justify-end gap-2 p-4 bg-gray-100">
        <button class="btn-indigo" @click="sendFeedback"><span class="mr-2 fas fa-comment fa-sm" />Internal Feedback</button>
        <button class="btn-indigo" @click="sendFeedback"><span class="mr-2 fas fa-envelope fa-sm" />Email Feedback</button>
        <button class="btn-indigo" @click="feedbackModal = false">Cancel</button>
      </div>
    </modal>

    <Head title="Application Details" />
    <div class="flex justify-between">
      <h1 class="text-2xl font-bold">Details</h1>
      <div class="flex">
        <Link v-if="license.status == 'License Issued'" class="mr-2 btn-indigo" :href="`/licenses/${license.id}/print`"><span class="mr-2 fas fa-eye fa-sm" /> License Preview</Link>
        <Link :href="`/licenses/${license.id}/edit`" class="mr-2 btn-indigo"><span class="mr-2 fas fa-edit fa-sm" /> Edit Application</Link>
        <button v-if="!['Draft', 'Pay Application'].includes(license.status) && userCan('add feedback')" class="btn-indigo" @click="feedbackModal = true"><span class="mr-2 fas fa-envelope fa-sm" />Send Feedback</button>
        <button v-if="isExpired(license) == 'Expired'" class="btn-indigo">Renew</button>
      </div>
    </div>
    <status-bar v-if="license.status != 'License Issued'" :license="license" />

    <!-- card view -->
    <div class="grid grid-cols-5 gap-4 px-2 py-4 mt-6 mb-4 rounded-lg bg-gray-50">
      <div class="flex flex-col items-center col-span-1">
        <img class="w-24 h-24 rounded-lg" :src="license.user.photo ? license.user.photo : `https://ui-avatars.com/api/?name=${license.user.first_name}+${license.user.last_name}&color=ffffff&background=2f365f`" width="32" height="32" alt="User" />
        <div class="pt-3 font-semibold">{{ license.user.first_name }} {{ license.user.last_name }}</div>
      </div>
      <div class="col-span-2 space-y-4">
        <div>
          <b class="font-semibold">License Number:</b> <span class="p-1 text-sm font-semibold text-white rounded-sm" :class="license.number ? 'bg-green-500' : 'bg-yellow-500'">{{ license.number ? license.number : 'NA' }}</span>
        </div>
        <div><b class="font-semibold">Discipline:</b> {{ license.form.discipline.name }}</div>
        <div><b class="font-semibold">License Name:</b> {{ license.form.name }}</div>
        <div><b class="font-semibold">Expiration Date:</b> {{ license.approved_at ? license.expiryDate : 'NA' }}</div>
      </div>
      <div class="col-span-2 space-y-4">
        <div>
          <b class="mr-2 font-semibold">License Status:</b>
          <span v-if="isExpired(license) == 'Expired'" class="p-1 text-sm font-semibold text-white bg-orange-500 rounded-sm">{{ isExpired(license) }} </span>
          <span v-if="isExpired(license) == 'Active'" class="p-1 text-sm font-semibold text-white bg-green-500 rounded-sm">{{ isExpired(license) }} </span>
          <span v-if="!license.approved_at" class="p-1 text-sm font-semibold text-white bg-gray-500 rounded-sm">{{ license.status }} </span>
        </div>
        <div><b class="font-semibold">Initial Issued Date:</b> {{ license.approved_at ? license.approved_at : 'NA' }}</div>
        <div><b class="font-semibold">Effective Date:</b> {{ license.approved_at ? license.approved_at : 'NA' }}</div>
        <div><b class="font-semibold">Affiliated Agency Name:</b> None</div>
      </div>
    </div>

    <h1 class="mt-4 mb-2 text-xl font-semibold"><span class="mr-2 fas fa-file fa-sm" /> Application</h1>
    <div class="overflow-x-auto bg-gray-50">
      <div v-if="license.form.type == 'steps'">
        <div v-for="step in license.form.form.steps" :key="step.id">
          <h3 class="p-4 font-semibold bg-gray-200 text-medium">{{ step.label }}</h3>
          <hr />

          <div class="grid grid-cols-3 gap-4 p-4">
            <div v-for="element in step.elements" :key="element.id" class="space-y-2">
              <div class="space-y-2">
                <template v-if="element.type != 'file'">
                  <div class="font-semibold">{{ element.label }}:</div>
                  <div>{{ license.form_data[element.name] }}</div>
                </template>
                <template v-if="element.type == 'file'">
                  <div class="font-semibold">{{ element.label }}:</div>
                  <template v-for="(file, index) in license.form_data[element.name]" :key="file">
                    <a class="block text-blue-600 hover:underline" :href="$page.props?.mediaPath + file.name" target="_blank" :title="file.name">
                      {{ file.name }}
                    </a>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="license.form.type == 'default'">
        <div class="flex justify-between bg-gray-200">
          <h3 class="p-4 font-semibold text-medium">{{ license.form.name }}</h3>
        </div>
        <hr />

        <div class="grid grid-cols-3 gap-4 p-4">
          <div v-for="element in license.form.form" :key="element.id" class="space-y-2">
            <template v-if="element.type != 'file'">
              <div class="font-semibold">{{ element.label }}:</div>
              <div>{{ license.form_data[element.name] }}</div>
            </template>
            <template v-if="element.type == 'file'">
              <div class="font-semibold">{{ element.label }}:</div>
              <template v-for="(file, index) in license.form_data[element.name]" :key="file">
                <a class="block text-blue-600 hover:underline" :href="$page.props?.mediaPath + file.name" target="_blank" :title="file.name"> {{ file.name }} </a>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-end gap-2 pt-4 pb-4">
      <div v-if="userCan('manage applications')" class="flex">
        <select v-if="!['License Issued', 'Draft', 'Pay Application', 'Declined'].includes(license.status)" v-model="status" class="w-48 mr-2 form-select">
          <option value="" selected disabled hidden>Select Status</option>
          <option value="approved">License Approved</option>
          <option value="rejected">License Rejected</option>
          <option>Documents Missing</option>
        </select>
        <loading-button v-if="!['License Issued', 'Draft', 'Pay Application', 'Declined'].includes(license.status)" :loading="loading" class="btn-indigo" @click="update"><span class="mr-2 fas fa-save fa-sm" />Update</loading-button>
      </div>

      <loading-button v-if="license.status == 'Pay Application'" :loading="loading" class="btn-indigo" @click="submit">Submit Application</loading-button>
      <Link v-if="license.status == 'Draft'" class="btn-indigo" :href="`/licenses/${license.id}/payment`"><span class="mr-2 fas fa-credit-card fa-sm" /> Pay Application Fee </Link>
    </div>
    <div v-if="!['Draft', 'Pay Application'].includes(license.status)" class="w-full pt-4">
      <div v-if="license.feedbacks.length != 0">
        <div class="flex justify-between">
          <p class="mb-2 text-lg font-semibold"><span class="mr-2 fas fa-comments" /> Comments History</p>
        </div>

        <div v-for="feedback in license.feedbacks" :key="feedback.index" class="mt-2 mb-2">
          <div class="p-2 border-green-500 border-l-3 bg-gray-50">
            <div class="flex pb-2">
              <div class="flex justify-between w-full">
                <div class="flex">
                  <p class="font-semibold">From:</p>
                  <p class="ml-2 text-sm">{{ feedback.user.first_name }} {{ feedback.user.last_name }}</p>
                </div>
                <p class="text-xs">{{ new Date(feedback.created_at).toLocaleString() }}</p>
              </div>
            </div>
            <div class="flex">
              <p class="font-semibold">Comment:</p>
              <p class="ml-2 text-sm">{{ feedback.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="userCan('check payment history')" class="mt-4 mb-6">
      <payment-history :license="license" withoutHead />
    </div>

    <div v-if="userCan('check activity log')">
      <license-history :license="license" withoutHead />
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import axios from 'axios'
import LoadingButton from '@/Shared/LoadingButton'
import TextareaInput from '@/Shared/TextareaInput'
import Modal from '@/Shared/Modal'
import StatusBar from '@/Shared/License/StatusBar'
import { Inertia } from '@inertiajs/inertia'
import { userCan } from '@/utils/Utils'
import LicenseHistory from '@/Shared/License/History'
import PaymentHistory from '@/Shared/License/PaymentHistory'

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    Modal,
    TextareaInput,
    StatusBar,
    LicenseHistory,
    PaymentHistory,
  },
  layout: Layout,
  props: {
    license: Object,
  },
  data() {
    return {
      downloading: false,
      loading: false,
      showModal: false,
      form: this.$inertia.form({ reason: '', feedback: '' }),
      status: '',
      feedbackModal: false,
    }
  },
  mounted() {},

  methods: {
    userCan,
    renderAnswer(question) {
      // File
      if (question.type == 6) return

      // Text, Number, Date
      if ([1, 2, 7].includes(question.type)) {
        return question.pivot.answer
      }

      // Checkbox
      if (question.type == 4) {
        let answerArray = JSON.parse(question.pivot.answer)

        const answerText = question.options
          .filter((o) => answerArray.includes(o.id))
          .map((o) => o.text)
          .join(', ')

        return answerText
      }

      // Radio button, dropdown
      if ([5, 3].includes(question.type)) {
        const option = question.options.find((o) => o.id == +question.pivot.answer)

        return option && option.text
      }
    },

    download(id) {
      this.downloading = true

      axios({
        url: `/attachments/${id}`,
        method: 'GET',
        responseType: 'blob', // important
      })
        .then((response) => {
          let filename = response.headers['content-disposition'].split('filename=')[1].split('.')[0]
          let extension = response.headers['content-disposition'].split('.')[1].split(';')[0]

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${filename}.${extension}`) //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {})
        .finally(() => (this.downloading = false))
    },

    approve() {
      Inertia.patch(`/licenses/${this.license.id}/approval`)
    },

    decline() {
      this.form.patch(`/licenses/${this.license.id}/rejection`, {
        onSuccess: () => (this.showModal = false),
      })
    },

    sendFeedback() {
      this.form.patch(`/licenses/${this.license.id}/feedback`, {
        onSuccess: () => (this.feedbackModal = false),
      })
    },

    update() {
      if (this.status == 'rejected') {
        this.showModal = true
      } else if (this.status == 'approved') {
        this.approve()
      }
    },

    submit() {
      Inertia.patch(`/licenses/${this.license.id}/submission`)
    },

    isExpired(license) {
      if (license.approved_at) {
        const approvedDate = new Date(license.approved_at)
        const today = new Date()
        const diffTime = Math.abs(today - approvedDate)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        if (diffDays > 60) {
          return 'Expired'
        } else {
          return 'Active'
        }
      }
    },
  },
}
</script>
