<script setup>
import { elements, selectInputTypes, allInputTypes, stepFormType, stepTemplate } from '@/formElements'
import { ref, onMounted, computed } from 'vue'
import draggable from 'vuedraggable'
import Input from '@/Shared/Input.vue'
// import { Accordion, AccordionPanel, AccordionHeader, AccordionContent } from 'flowbite-vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { v4 } from 'uuid'
import { useForm } from '@inertiajs/inertia-vue3'
import StepsIndicator from './StepsIndicator.vue'
import ValidationErrors from './ValidationErrors.vue'
import AccordionBasic from '@/components/AccordionBasic.vue'

const props = defineProps(['update_form', 'forms', 'disciplines', 'users'])
const selectedElementIndex = ref()
const currentStep = ref(1)
const preview = ref(false)
const form = useForm({
  name: props.update_form?.name || '',
  type: props.update_form?.type || 'default',
  form: JSON.parse(JSON.stringify(props.update_form?.form || [])),
  copiedFrom: null,
  discipline_id: props.update_form?.discipline_id || null,
  assigned_admin: props.update_form?.assigned_admin || null,
  business_type: props.update_form?.business_type || null,

  fees: props.update_form?.fees || null,
  prefix: props.update_form?.prefix || null,
  postfix: props.update_form?.postfix || null,
  renewal_fees: props.update_form?.renewal_fees || null,
  expiry_type: props.update_form?.expiry_type || null,
  expiry_year: props.update_form?.expiry_year || null,
  expiry_date: props.update_form?.expiry_date || null,
  is_ce_required: props.update_form?.is_ce_required || null,
  is_accusation_required: props.update_form?.is_accusation_required || null,
})
function log(evt, elementIndex) {
  console.log(props.forms)
}
const onClickAway = () => {
  selectedStepIndex.value = null
  selectedStepElementIndex.value = null
  selectedElementIndex.value = null
}
const onStepClick = (step) => (currentStep.value = step)
const onStepRemove = (stepIndex) => {
  form.form.steps = form.form.steps.filter((s, i) => i != stepIndex)
  console.log(form.form.steps)
  currentStep.value = 1
}
const currentStepIndex = computed(() => currentStep.value - 1)
const onStepAdd = () => form.form.steps.push({ ...stepTemplate, id: v4() })
function onFormTypeChange() {
  if (form.type == 'steps') {
    // if (form.form[0]) {
    //     form.form = { ...stepFormType, steps: [{ ...stepTemplate, elements: form.form, id: v4() }] }
    // } else {
    form.form = stepFormType
    // }
  }
  if (form.type == 'default' && form.form.type == 'steps') {
    form.form = []
    //     form.form = form.form.steps.reduce((elements, step) => {
    //         // console.log(step.elements, '1');
    //         return [...elements, ...step.elements]
    //     }, []) || []
  }
  console.log(form.form, form.form.type, 'form')
}

function createUpdateOrDeleteForm(deleteForm) {
  // if (form.type == 'steps') currentStep.value = 1
  // return console.log(.data());
  if (deleteForm) {
    return (
      confirm('Are you sure want to delete this Workflow: ' + props.update_form.name) &&
      form.delete('/forms/' + props.update_form.id, {
        preserveState: true,
      })
    )
  }
  if (props.update_form) {
    return form.patch('/forms/' + props.update_form?.id, {
      preserveState: true,
    })
  }
  return form.post('/forms', {
    preserveState: true,
  })
}
function selectElementByIndex(index) {
  selectedElementIndex.value = index
}

const selectedStepIndex = ref(currentStep.value)
const selectedStepElementIndex = ref()
function selectElementByStepIndex(stepIndex, elIndex) {
  selectedStepIndex.value = stepIndex
  selectedStepElementIndex.value = elIndex
}

function test() {
  log()
}
function copyForm() {
  const foundedForm = props.forms.find((f) => f.id == form.copiedFrom)
  if (!foundedForm) return
  form.name = foundedForm.name
  form.form = foundedForm.form
  form.type = foundedForm.form.type || 'default'
}
function removeAt(index) {
  if (form.form[index]) {
    form.form = form.form.filter((el, i) => i != index)
  }
}
function removeStepElementAt(stepIndex, index) {
  if (form.type != 'steps' || !form.form.steps[stepIndex]?.elements[index]) return
  selectedElementIndex.value = null
  selectedStepIndex.value = null
  selectedStepElementIndex.value = null
  form.form.steps[stepIndex].elements = form.form.steps[stepIndex].elements.filter((el, i) => i != index)
}
function getInputField(field) {
  if (selectInputTypes.includes(field.schema.type)) return { ...field.schema, options: [{ label: 'label_1', value: 'value_1' }], name: field.schema.type, id: v4() }
  return { ...field.schema, name: field.schema.type, id: v4() }
}
function clearForm() {
  return (form.form = [])
}

function isDefaultElementSelected() {
  return typeof selectedElementIndex.value?.toString() === 'string'
}
function isStepElementSelected() {
  return typeof selectedStepIndex.value?.toString() == 'string' && typeof selectedStepElementIndex?.value?.toString() == 'string'
}

function totalSteps() {
  return form.form.steps.length
}
function isFirstStep() {
  return currentStep.value === 1
}
function isLastStep() {
  return currentStep.value === totalSteps()
}
function previousStep() {
  if (isFirstStep()) return
  currentStep.value--
}
function nextStep() {
  if (isLastStep()) return
  currentStep.value++
}
</script>

<template>
  <div>
    <div class="grid grid-cols-12 gap-5 min-h-[80vh]" @mousedown.self="onClickAway">
      <div @mousedown="onClickAway" class="bg-white col-span-12 shadow-sm sm:rounded-lg h-full" v-if="form.type == 'steps'" :key="`stepDetails-${currentStepIndex}`">
        <div class="px-4 py-2 overflow-x-hidden grid grid-cols-12 gap-4" v-if="form.form.steps[currentStepIndex]">
          <div class="col-span-3">
            <FormKit class="w-full" v-model="form.form.steps[currentStepIndex].label" :key="'label-' + form.form.steps[currentStepIndex].id" label="Step label" />
          </div>
          <div class="col-span-5">
            <FormKit class="w-full" v-model="form.form.steps[currentStepIndex].description" :key="'description-' + form.form.steps[currentStepIndex].id" label="Step description" />
          </div>
          <div class="col-span-2">
            <FormKit class="w-full" v-model="form.form.steps[currentStepIndex].if.name" :key="'if-' + form.form.steps[currentStepIndex].id" label="Show If" placeholder="Field name" />
          </div>
          <div class="col-span-2">
            <FormKit class="w-full" v-model="form.form.steps[currentStepIndex].if.equalsTo" :key="'equalsTo-' + form.form.steps[currentStepIndex].id" label="Equals To" placeholder="expected value" />
          </div>
        </div>
      </div>
      <div @mousedown="onClickAway" class="bg-white col-span-3 max-h-[80vh] overflow-y-auto overflow-x-hidden shadow-sm sm:rounded-lg h-full">
        <div class="px-2 py-4 overflow-x-hidden">
          <draggable animation="150" ghost-class="hidden" :group="{ name: 'elements', put: false, pull: 'clone' }" :clone="getInputField" class="w-full" :list="elements" @change="log" item-key="label">
            <template #header> Form Input Elements </template>
            <template #item="{ element }">
              <div class="element-container element-container-enabled">
                <div class="element-icon-container">
                  <FontAwesomeIcon :icon="element.icon" class="icon element-icon" />
                </div>
                <div class="element-text-container">
                  <div class="element-text-label" v-text="element.label"></div>
                  <div class="element-text-description" v-text="element.description"></div>
                </div>
              </div>
            </template>
          </draggable>
        </div>
      </div>
      <div class="bg-white max-h-[80vh] overflow-y-auto overflow-x-hidden col-span-6 shadow-sm sm:rounded-lg self-start relative min-h-[250px]">
        <template v-if="form.type == 'default' && !form.form.steps" :key="'type-' + form.type + '-totalElements-' + v4()">
          <FontAwesomeIcon icon="fas fa-eraser" title="clear Form" v-if="form.form?.length != 0" class="icon element-icon cursor-pointer absolute rounded-t text-sm top-[-24px] right-0 hover:text-gray-500 text-gray-400 bg-white p-2" @click="clearForm" />
          <draggable :emptyInsertThreshold="250" class="w-full grid grid-cols-12 gap-6 p-10" :list="form.form" group="elements" @change="log" item-key="id">
            <template #header> </template>
            <template #item="{ element, index }">
              <div :id="v4()" :class="`col-span-${element.cols || 12} ${selectedElementIndex == index ? 'border' : ''} relative group cursor-pointer rounded rounded-t-none border-indigo-500 hover:border`" @click="selectElementByIndex(index)">
                <p class="bg-indigo-500 text-white text-xs absolute top-[-16px] left-[-1px] rounded-t px-2 group-hover:inline" :class="`${selectedElementIndex == index ? 'inline' : 'hidden'}`" v-html="element.name"></p>
                <FontAwesomeIcon icon="fas fa-times" class="bg-indigo-500 text-white text-xs absolute top-[-13px] right-[-1px] rounded-t px-2 group-hover:inline cursor-pointer hover:bg-indigo-700" :class="`${selectedElementIndex == index ? 'inline' : 'hidden'}`" @click="removeAt(index)" />
                <div class="absolute w-full h-full z-50"></div>
                <FormKit :readonly="!preview" class="w-full" v-bind="element" :key="v4() + JSON.stringify(element.options)" />
                <!-- <Input :readonly="!preview" :disabled='!preview' :type="element.type || 'text'"
                                :helper="element.description" v-bind="element" /> -->
              </div>
            </template>
          </draggable>
        </template>
        <template v-if="form.type == 'steps'">
          <StepsIndicator :current-step="currentStep" :totalSteps="parseInt(totalSteps())" :handleClick="onStepClick" addable removable :onRemove="onStepRemove" :onAdd="onStepAdd" />
          <template v-for="(step, stepIndex) in form.form.steps" :key="'type-' + form.type + 'step-' + step.id + 'totalSteps' + v4()">
            <template v-if="step && currentStep == stepIndex + 1">
              <FontAwesomeIcon icon="fas fa-eraser" title="clear Form" v-if="form.form?.length != 0" class="icon element-icon cursor-pointer absolute rounded-t text-sm top-[-24px] right-0 hover:text-gray-500 text-gray-400 bg-white p-2" @click="clearForm" />
              <draggable :emptyInsertThreshold="250" class="w-full grid grid-cols-12 gap-6 p-10" :list="step.elements" group="elements" @change="log" item-key="id">
                <template #header> </template>
                <template #item="{ element, index }">
                  <div :id="v4()" :class="`col-span-${element.cols || 12} ${selectedStepIndex == stepIndex && selectedStepElementIndex == index ? 'border' : ''} relative group cursor-pointer rounded rounded-t-none border-indigo-500 hover:border`" @click="selectElementByStepIndex(stepIndex, index)">
                    <p class="bg-indigo-500 text-white text-xs absolute top-[-16px] left-[-1px] rounded-t px-2 group-hover:inline" :class="`${selectedStepIndex == stepIndex && selectedStepElementIndex == index ? 'inline' : 'hidden'}`" v-html="element.name"></p>

                    <FontAwesomeIcon icon="fas fa-times" class="bg-indigo-500 text-white text-xs absolute top-[-13px] right-[-1px] rounded-t px-2 group-hover:inline cursor-pointer hover:bg-indigo-700" :class="`${selectedStepIndex == stepIndex && selectedStepElementIndex == index ? 'inline' : 'hidden'}`" @click="removeStepElementAt(stepIndex, index)" />
                    <div class="absolute w-full h-full z-50"></div>

                    <FormKit :readonly="!preview" class="w-full" v-bind="element" :key="v4() + JSON.stringify(element.options)" />
                    <!-- <Input :readonly="!preview" :disabled='!preview' :type="element.type || 'text'"
                                :helper="element.description" v-bind="element" /> -->
                  </div>
                </template>
              </draggable>
            </template>
          </template>
          <div class="flex justify-between px-10 pb-10">
            <div>
              <button class="btn-indigo bg-gray-600" color="light" v-if="!isFirstStep()" @click="previousStep()">Previous</button>
            </div>
            <div>
              <button class="btn-indigo" v-if="!isLastStep()" @click="nextStep()">Next</button>
            </div>
          </div>
        </template>
      </div>
      <div class="bg-white col-span-3 max-h-[80vh] overflow-y-auto overflow-x-hidden shadow-sm sm:rounded-lg">
        <div class="py-4 px-2 grid grid-cols-1 gap-4" v-if="!isDefaultElementSelected() && !isStepElementSelected()">
          <Input v-model="form.name" placeholder="Please enter workflow name" label="Workflow Name" :errors="[form.errors?.name || '']" required />
          <Input v-if="disciplines" type="select" v-model="form.discipline_id" placeholder="Select discipline" label="Discipline" :options="disciplines.map((d) => ({ value: d.id, label: d.name }))" required />
          <Input v-if="users" type="select" v-model="form.assigned_admin" placeholder="Select responsible staff" label="Responsible Staff" :options="users.map((u) => ({ value: u.id, label: u.first_name + ' ' + u.last_name }))" required />

          <FormKit
            v-model="form.business_type"
            type="radio"
            label="Business Type"
            placeholder="please select Business Type"
            :options="[
              { label: 'Individual', value: 'individual' },
              { label: 'Business', value: 'business' },
            ]"
            required
          />
          <FormKit
            v-model="form.expiry_type"
            type="radio"
            label="Expiry Type"
            :options="[
              { label: 'By Year', value: 'year' },
              { label: 'By Specific Date', value: 'specific' },
            ]"
            required
          />
          <FormKit v-if="form.expiry_type == 'year'" v-model="form.expiry_year" type="number" label="Years" help="Valid for (n) years after creation." required />
          <!-- <FormKit v-if="form.expiry_type == 'specific'" v-model="form.expiry_date" type="month"
            label="Select Expiry Month" required /> -->

          <div class="grid grid-cols-2 gap-4 pb-4 relative" v-if="form.expiry_type == 'specific'">
            <FormKit label="Day" />
            <FormKit label="Month" />
          </div>
          <Input type="number" step=".01" v-model="form.fees" placeholder="Enter Fee" label="Application Fee" required />
          <Input type="number" step=".01" v-model="form.renewal_fees" placeholder="Enter renewal fee" label="License Renewal Fee" required />
          <FormKit v-model="form.is_ce_required" type="checkbox" label="Is CE required?" required />
          <FormKit v-model="form.is_accusation_required" type="checkbox" label="Is Association required?" required />

          <div class="grid grid-cols-2 gap-4 pb-4 relative">
            <FormKit v-model="form.prefix" label="Prefix" />
            <FormKit v-model="form.postfix" label="Postfix" />
          </div>
          <hr />

          <Input
            helper="note: Changing this will clear the form."
            v-model="form.type"
            type="select"
            :change="onFormTypeChange"
            label="Form Type"
            :options="[
              { label: 'Single Step', value: 'default' },
              { label: 'Multi Step', value: 'steps' },
            ]"
            required
          />

          <Input :change="copyForm" v-if="forms" type="select" v-model="form.copiedFrom" placeholder="Copy from other form" label="Copy From" :options="forms.map((f) => ({ value: f.id, label: f.name }))" required />
        </div>
        <template v-if="isDefaultElementSelected()">
          <AccordionBasic title="Properties" :open="true">
            <div class="grid grid-cols-1 gap-4">
              <FormKit v-if="typeof form.form[selectedElementIndex]?.name != 'undefined'" v-model="form.form[selectedElementIndex].name" placeholder="Input name" label="Name" />
              <FormKit v-if="typeof form.form[selectedElementIndex]?.label != 'undefined'" v-model="form.form[selectedElementIndex].label" placeholder="Input Label" label="Label" />
              <FormKit v-if="typeof form.form[selectedElementIndex]?.placeholder != 'undefined'" v-model="form.form[selectedElementIndex].placeholder" placeholder="Input Placeholder" label="Placeholder" />
              <FormKit v-if="typeof form.form[selectedElementIndex]?.type != 'undefined'" type="select" :options="allInputTypes.map((t) => ({ value: t, label: t }))" v-model="form.form[selectedElementIndex].type" placeholder="Input type" label="Type" />
              <FormKit v-if="typeof form.form[selectedElementIndex]?.cols != 'undefined'" v-model="form.form[selectedElementIndex].cols" placeholder="Input Columns" label="Columns" type="number" />
              <FormKit v-if="typeof form.form[selectedElementIndex]?.description != 'undefined'" v-model="form.form[selectedElementIndex].description" type="textarea" placeholder="Input Description" label="Description" />
              <FormKit v-if="typeof form.form[selectedElementIndex]?.required != 'undefined'" type="checkbox" v-model="form.form[selectedElementIndex].required" label="Required" />
            </div>
          </AccordionBasic>
          <AccordionBasic title="Options" :key="`selectedElement-${selectedElementIndex}-Options`" v-if="typeof form.form[selectedElementIndex]?.name != 'undefined' && selectInputTypes.includes(form.form[selectedElementIndex]?.type)">
            <div class="grid grid-cols-2 gap-4 pb-4 relative" v-for="(option, index) in form.form[selectedElementIndex].options" :key="index">
              <FontAwesomeIcon icon="fas fa-times" class="absolute top-0 text-sm right-0 hover:text-gray-500 cursor-pointer" @click="form.form[selectedElementIndex].options = form.form[selectedElementIndex].options.filter((el, i) => i != index)" />
              <FormKit v-model="form.form[selectedElementIndex].options[index].value" label="Value" />
              <FormKit v-model="form.form[selectedElementIndex].options[index].label" label="Label" />
            </div>
            <button class="btn-indigo" size="sm" @click="form.form[selectedElementIndex].options.push({ label: 'label', value: 'value' })">
              <FontAwesomeIcon icon="fas fa-plus" />
            </button>
          </AccordionBasic>
          <AccordionBasic title="Condition" v-if="typeof form.form[selectedElementIndex] != 'undefined'">
            <div class="grid grid-cols-2 gap-4 pb-4 relative">
              <FormKit name="if" v-model="form.form[selectedElementIndex]['if']['name']" label="Show If" />
              <FormKit name="equalsTo" v-model="form.form[selectedElementIndex]['if']['equalsTo']" label="Equals To" />
            </div>
          </AccordionBasic>
        </template>
        <template v-if="isStepElementSelected()">
          <AccordionBasic title="Properties" :open="true">
            <div class="grid grid-cols-1 gap-4">
              <FormKit v-if="typeof form.form.steps[selectedStepIndex]?.elements[selectedStepElementIndex]?.name != 'undefined'" v-model="form.form.steps[selectedStepIndex].elements[selectedStepElementIndex].name" placeholder="Input name" label="Name" />
              <FormKit v-if="typeof form.form.steps[selectedStepIndex]?.elements[selectedStepElementIndex]?.label != 'undefined'" v-model="form.form.steps[selectedStepIndex].elements[selectedStepElementIndex].label" placeholder="Input Label" label="Label" />
              <FormKit v-if="typeof form.form.steps[selectedStepIndex]?.elements[selectedStepElementIndex]?.placeholder != 'undefined'" v-model="form.form.steps[selectedStepIndex].elements[selectedStepElementIndex].placeholder" placeholder="Input Placeholder" label="Placeholder" />

              <FormKit v-if="typeof form.form.steps[selectedStepIndex]?.elements[selectedStepElementIndex]?.type != 'undefined'" type="select" :options="allInputTypes.map((t) => ({ value: t, label: t }))" v-model="form.form.steps[selectedStepIndex].elements[selectedStepElementIndex].type" placeholder="Input type" label="Type" />

              <FormKit v-if="typeof form.form.steps[selectedStepIndex]?.elements[selectedStepElementIndex]?.cols != 'undefined'" v-model="form.form.steps[selectedStepIndex].elements[selectedStepElementIndex].cols" placeholder="Input Columns" label="Columns" type="number" />
              <FormKit v-if="typeof form.form.steps[selectedStepIndex]?.elements[selectedStepElementIndex]?.description != 'undefined'" v-model="form.form.steps[selectedStepIndex].elements[selectedStepElementIndex].description" type="textarea" placeholder="Input Description" label="Description" />
              <FormKit v-if="typeof form.form.steps[selectedStepIndex]?.elements[selectedStepElementIndex]?.required != 'undefined'" type="checkbox" v-model="form.form.steps[selectedStepIndex].elements[selectedStepElementIndex].required" label="Required" />
            </div>
          </AccordionBasic>
          <AccordionBasic title="Options" v-if="typeof form.form.steps[selectedStepIndex]?.elements[selectedStepElementIndex]?.name != 'undefined' && selectInputTypes.includes(form.form.steps[selectedStepIndex]?.elements[selectedStepElementIndex]?.type)">
            <div class="grid grid-cols-2 gap-4 pb-4 relative" v-for="(option, index) in form.form.steps[selectedStepIndex].elements[selectedStepElementIndex].options" :key="`SelectedStep-${selectedStepIndex}-selectedElement-${selectedStepElementIndex}`">
              <FontAwesomeIcon icon="fas fa-times" class="absolute top-0 text-sm right-0 hover:text-gray-500 cursor-pointer" @click="form.form.steps[selectedStepIndex].elements[selectedStepElementIndex].options = form.form.steps[selectedStepIndex].elements[selectedStepElementIndex].options.filter((el, i) => i != index)" />
              <FormKit v-model="option.value" label="Value" />
              <FormKit v-model="option.label" label="Label" />
            </div>
            <button class="btn-indigo" size="sm" @click="form.form.steps[selectedStepIndex].elements[selectedStepElementIndex].options.push({ label: 'label', value: 'value' })">
              <FontAwesomeIcon icon="fas fa-plus" />
            </button>
          </AccordionBasic>
          <AccordionBasic title="Condition" v-if="typeof form.form.steps[selectedStepIndex]?.elements[selectedStepElementIndex] != 'undefined'">
            <div class="grid grid-cols-2 gap-4 pb-4 relative">
              <FormKit name="if" v-model="form.form.steps[selectedStepIndex].elements[selectedStepElementIndex]['if']['name']" label="Show If" />
              <FormKit name="equalsTo" v-model="form.form.steps[selectedStepIndex].elements[selectedStepElementIndex]['if']['equalsTo']" label="Equals To" />
            </div>
          </AccordionBasic>
        </template>
      </div>
    </div>

    <div class="flex justify-end gap-4 mt-6 sm:px-6 lg:px-8">
      <button class="btn-indigo bg-red-600" color="red" v-if="update_form" @click="createUpdateOrDeleteForm(true)" type="button">Delete</button>
      <button class="btn-indigo" @click="createUpdateOrDeleteForm()" type="button">{{ update_form ? 'Update' : 'Save' }}</button>
    </div>
  </div>
</template>

<style scoped>
.element-text-label {
  font-weight: 600;
  line-height: 1.125;
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
}

.element-icon-container {
  margin-right: 0.75rem;
  display: flex;
  height: 2rem;
  width: 2rem;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
  font-size: 0.8125rem;
  line-height: 1.125rem;
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.element-container {
  margin: 0.5rem -0.625rem;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  border-width: 1px;
  border-color: rgb(23 196 149 / var(--tw-border-opacity));
  --tw-border-opacity: 0;
  background-color: rgb(23 196 149 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0;
  padding: 0.625rem;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
}

.element-container-enabled:hover {
  --tw-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0px 0px 10px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.element-text-description {
  font-size: 0.8125rem;
  line-height: 1.125rem;
  line-height: 1.375;
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.icon {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  color: inherit;
  vertical-align: -0.125em;
}

.element-icon-container {
  margin-right: 0.75rem;
  display: flex;
  height: 2rem;
  width: 2rem;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
  font-size: 0.8125rem;
  line-height: 1.125rem;
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.element-icon {
  max-height: 1.25rem;
  max-width: 1.25rem;
}
</style>
