
<template>
  <Head title="Login" />
  <div class="p-6 bg-blue-800 min-h-screen flex flex-col justify-center items-center">
    <div class="grid grid-cols-2 w-full max-w-3xl bg-white rounded-lg shadow-xl overflow-hidden">
      <div class="flex flex-col justify-center flex-wrap content-center">
        <logo class="block mx-auto max-w-xs fill-white h-50 w-64" />
      </div>

      <form class="mt-8 bg-white rounded-lg shadow-xl overflow-hidden" @submit.prevent="login">
        <div class="px-10 py-12">
          <h1 class="text-center text-2xl font-bold">Login</h1>
          <div class="mt-6 mx-auto w-24 border-b-2" />
          <text-input v-model="form.email" :error="form.errors.email" class="mt-10" label="Email" type="email" autofocus autocapitalize="off" />
          <text-input v-model="form.password" :error="form.errors.password" class="mt-6" label="Password" type="password" />
          <div class="flex justify-between items-center mt-6">
            <label class="flex select-none" for="remember">
              <input id="remember" v-model="form.remember" class="mr-1" type="checkbox" />
              <span class="text-sm">Remember Me</span>
            </label>
            <a class="text-sm text-indigo-600 hover:text-indigo-800" href="/signUp">Sign Up</a>
          </div>
        </div>
        <div class="flex px-10 py-4 bg-gray-100 border-t border-gray-100">
          <loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit"><span class="fas fa-sign-in fa-sm mr-2" /> Login</loading-button>
        </div>
      </form>
    </div>
    <div class="pt-10 flex text-sm">
      <p class="text-gray-400">Developed by</p>
      <a href="https://consulatesys.com/" class="text-bold ml-1 hover:underline">Consulate SYS</a>
    </div>
  </div>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import Logo from '@/Shared/Logo'
import TextInput from '@/Shared/TextInput'
import LoadingButton from '@/Shared/LoadingButton'

export default {
  components: {
    Head,
    LoadingButton,
    Logo,
    TextInput,
  },
  data() {
    return {
      form: this.$inertia.form({
        email: 'admin@gmail.com',
        password: 'secret',
        remember: false,
      }),
    }
  },

  methods: {
    login() {
      this.form.post('/login')
    },
  },
}
</script>
