<template>
  <div>
    <Head title="Activity Logs" />
    <div class="flex justify-between items-center">
      <h1 class="mb-8 text-2xl font-bold">Activity Logs</h1>
      <div class="flex items-center justify-between mb-6">
        <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
          <label class="block text-gray-700">Logged Entity:</label>
          <select v-model="form.loggedModel" class="form-select mt-1 w-full">
            <option :value="null" />
            <option v-for="model in loggedModels" :key="model.id" :value="model">{{ model }}</option>
          </select>
          <label class="block mt-4 text-gray-700">Causer:</label>
          <select v-model="form.causer" class="form-select mt-1 w-full">
            <option :value="null" />
            <option v-for="user in users" :key="user.id" :value="user.id">{{ user.first_name }}</option>
          </select>
        </search-filter>
      </div>
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="p-4">Logged Entity</th>
          <th class="p-4">Causer</th>
          <th class="p-4">Description</th>
          <th class="p-4">Logged Date</th>
          <th class="p-4">Changes</th>
        </tr>
        <tr v-for="log in logs.data" :key="log.id" class="hover:bg-gray-100 focus-within:bg-gray-100 text-sm">
          <td class="border-t">
            <span class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ log.log_name }}
            </span>
          </td>
          <td class="border-t">
            <span v-if="log.causer" class="flex items-center px-6 py-2" tabindex="-1"> {{ log.causer.last_name }} </span>
            <span v-else>Seeder</span>
          </td>
          <td class="border-t">
            <span class="flex items-center px-6 py-2" tabindex="-1">
              {{ log.description }}
            </span>
          </td>
          <td class="border-t">
            <span class="flex items-center px-6 py-2" tabindex="-1">
              {{ log.created_at }}
            </span>
          </td>
          <td class="border-t">
            <span class="flex items-center px-6 py-2" tabindex="-1">
              {{ log.properties }}
            </span>
          </td>
        </tr>
        <tr v-if="logs.data.length === 0">
          <td class="px-6 py-4 border-t" colspan="4">No Activity Log Found!</td>
        </tr>
      </table>
    </div>
    <pagination class="mt-6" :links="logs.links" />
  </div>
</template>

<script>
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import SearchFilter from '@/Shared/SearchFilter'
import Pagination from '@/Shared/Pagination'
import { Head } from '@inertiajs/inertia-vue3'

export default {
  metaInfo: { title: 'Activity Logs' },
  components: {
    SearchFilter,
    Pagination,
    Head,
  },
  layout: Layout,
  props: {
    filters: Object,
    logs: Object,
    loggedModels: Array,
    users: Array,
  },
  data() {
    return {
      downloading: false,
      form: {
        search: this.filters.search,
        causer: this.filters.causer,
        loggedModel: this.filters.loggedModel,
      },
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function () {
        this.$inertia.get('activity-logs', pickBy(this.form), { preserveState: true })
      }, 150),
    },
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
  },
}
</script>
