<template>
  <div>
    <Head title="Create Discipline" />
    <h1 class="mb-8 text-xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/disciplines">Disciplines</Link>
      <span class="text-indigo-400 font-medium">/</span> Create
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="store">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <div class="flex gap-4 mb-2">
            <label class="mb-4">Business Type:</label>
            <label for="business"> <input id="business" v-model="form.business_type" type="radio" value="0" name="business_type" /> Business </label>
            <label for="individual"> <input id="individual" v-model="form.business_type" type="radio" value="1" name="business_type" /> Individual </label>
          </div>
          <text-input v-model="form.name" :error="form.errors.name" class="pb-8 pr-6 w-full" label="Name" />
          <textarea-input v-model="form.description" :error="form.errors.description" class="pb-8 pr-6 w-full" label="Description" />
        </div>
        <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
          <loading-button :loading="form.processing" class="btn-indigo" type="submit">Create Discipline</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import TextareaInput from '@/Shared/TextareaInput'
import LoadingButton from '@/Shared/LoadingButton'

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    TextInput,
    TextareaInput,
  },

  layout: Layout,

  remember: 'form',

  data() {
    return {
      form: this.$inertia.form({
        name: '',
        description: '',
        business_type: '',
      }),
    }
  },
  methods: {
    store() {
      this.form.post('/disciplines')
    },
  },
}
</script>
