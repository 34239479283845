<template>
  <div>
    <Head title="Roles & Permissions" />
    <div class="flex justify-between items-center">
      <h1 class="mb-8 text-2xl font-bold">Roles & Permissions</h1>
      <div class="flex items-center justify-between mb-6">
        <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
          <label class="block text-gray-700">Trashed:</label>
          <select v-model="form.trashed" class="form-select mt-1 w-full">
            <option :value="null" />
            <option value="with">With Trashed</option>
            <option value="only">Only Trashed</option>
          </select>
        </search-filter>
        <Link class="btn-indigo mr-2" href="/roles/create">
          <span><span class="fas fa-plus fa-sm mr-2" />Add Role</span>
        </Link>
        <Link class="btn-indigo" href="/permissions/create">
          <span><span class="fas fa-plus fa-sm mr-2" />Add Permission</span>
        </Link>
      </div>
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="p-4">Name</th>
          <th class="p-4">Permissions Count</th>
        </tr>
        <tr v-for="role in roles" :key="role.id" class="hover:bg-gray-100 focus-within:bg-gray-100 text-sm">
          <td class="border-t">
            <Link class="px-4 py-2 flex items-center focus:text-indigo-500" :href="`/roles/${role.id}/edit`">
              {{ role.name }}
              <icon v-if="role.deleted_at" name="trash" class="flex-shrink-0 w-3 h-3 fill-gray-400 ml-2" />
            </Link>
          </td>
          <td class="border-t">
            <Link class="px-4 py-2 flex items-center" :href="`/roles/${role.id}/edit`" tabindex="-1">
              <template v-if="role.permissions.length == 0">
                <span class="px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-orange-400 rounded-full"> No Prermissions Assigned</span>
              </template>

              <template v-else>
                <span class="px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-green-600 rounded-md">{{ role.permissions.length }} Permission(s) Assigned.</span>
              </template>
            </Link>
          </td>
          <td class="border-t w-px">
            <Link class="px-4 py-2 flex items-center" :href="`/roles/${role.id}/edit`" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </Link>
          </td>
        </tr>
        <tr v-if="roles.length === 0">
          <td class="border-t px-6 py-4 text-sm" colspan="4">No roles found.</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import SearchFilter from '@/Shared/SearchFilter'

export default {
  metaInfo: { title: 'Roles' },
  components: {
    Icon,
    SearchFilter,
    Head,
    Link,
  },
  layout: Layout,
  props: {
    filters: Object,
    roles: Array,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        trashed: this.filters.trashed,
      },
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function () {
        this.$inertia.get('/roles', pickBy(this.form), { preserveState: true })
      }, 150),
    },
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
  },
}
</script>
