import { createApp, h } from 'vue'
import { InertiaProgress } from '@inertiajs/progress'
import { createInertiaApp } from '@inertiajs/inertia-vue3'
import { plugin as FormKitPlugin, defaultConfig } from '@formkit/vue'
import VueClickAway from 'vue3-click-away'
import '@formkit/themes/genesis'
import './Shared/Charts/ChartjsConfig'
import { ZiggyVue } from 'ziggy'
// import { Ziggy } from './ziggy';
// window.axios = require('axios')
// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

InertiaProgress.init()

import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)
dom.watch(fas)

createInertiaApp({
  resolve: name => require(`./Pages/${name}`),
  title: title => title ? `${title} - DLCP` : 'DLCP',
  setup({ el, App, props, plugin }) {
    createApp({ render: () => h(App, props) })
      .use(plugin, ZiggyVue)
      .use(FormKitPlugin, defaultConfig({
        theme: 'genesis',

      }))
      .use(VueClickAway)
      .mixin({ methods: { route: window.route } })
      .mount(el)
  },
})
