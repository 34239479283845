<template>
  <div class="w-full">

    <Head title="Create Application" />
    <h1 class="mb-6 text-2xl font-bold text-center mt-4">New Occupational and Professional License Application</h1>
    <div class="flex justify-center">
      <div class="w-[768px] overflow-hidden p-4">
        <!-- <h3 class="p-4 font-semibold text-medium bg-gray-200">License Details</h3> -->
        <form v-if="form" @submit.prevent="store">
          <Input v-model="discipline_id" :change="onDisciplineSelect" placeholder="Select a discipline" type="select"
            label="Discipline" name="license_type" class="mb-6"
            :options="disciplines.map((f) => ({ label: f.name, value: f.id }))" />
          <Input v-if="discipline_id && forms" v-model="form.form_id" :change="onFormTypeSelect"
            placeholder="Select a license type" type="select" label="License Type" name="license_type"
            :options="forms.map((f) => ({ label: f.name, value: f.id }))" />
          <hr class="my-6" />
          <div v-if="form.type == 'default' && !form.form.steps"
            :key="'type-' + form.type + '-totalElements-' + form.form.length"
            class="w-full grid grid-cols-12 gap-3 bg-white rounded-lg p-10 rounded-t-none">
            <template v-for="element in form.form" :key="element.id">
              <div :class="`col-span-${element.cols || 12}`">
                <FormKit v-bind="element" v-model="form.form_data[element.name]" class="w-full"
                  :errors="[form.errors[element.name] || '']" />
              </div>
            </template>
          </div>
          <template v-if="form.type == 'steps'">
            <StepsIndicator :current-step="currentStep" :total-steps="parseInt(totalSteps())"
              :handle-click="onStepClick" />
            <div v-for="(step, stepIndex) in form.form.steps"
              :key="'type-' + form.type + 'step-' + step.id + 'totalSteps' + form.form.steps.length">
              <h3 v-if="step.label && step && currentStep == stepIndex + 1"
                class="p-4 font-semibold text-medium bg-gray-200 rounded rounded-b-none">{{ step.label }}</h3>
              <hr />
              <div v-if="step && currentStep == stepIndex + 1"
                class="w-full grid grid-cols-12 gap-3 bg-white rounded-lg p-10 rounded-t-none">
                <template v-for="element in step.elements" :key="element.id">
                  <div :class="`col-span-${element.cols || 12}`">
                    <FormKit v-bind="element" v-model="form.form_data[element.name]" class="w-full"
                      :errors="[form.errors[element.name] || '']" />
                  </div>
                </template>
              </div>
            </div>
            <div class="flex justify-between mt-6">
              <div>
                <button v-if="!isFirstStep()" class="btn-indigo bg-gray-600" type="button" color="light"
                  @click="previousStep()">Previous</button>
              </div>
              <div>
                <button v-if="!isLastStep()" class="btn-indigo" type="button" @click="nextStep()">Next</button>
              </div>
            </div>
          </template>
          <div class="flex items-center justify-end gap-2 mt-4">
            <loading-button type="button" :loading="form.processing" class="btn-indigo" @click="store(true)"><span
                class="fas fa-save fa-sm mr-2" /> {{ update_license ? 'Save Application' : 'Save Draft'
              }}</loading-button>
            <!-- <loading-button type="button" :loading="form.processing" class="btn-indigo" @click="store()"><span class="fas fa-save fa-sm mr-2" />Submit Application</loading-button> -->
            <!-- <loading-button v-if="!update_license" :loading="form.processing" class="btn-indigo" type="submit">Submit Application</loading-button> -->
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import ContactInfo from '@/Shared/License/ContactInfo'
import axios from 'axios'
import StepsIndicator from '@/Shared/StepsIndicator.vue'
import Input from '@/Shared/Input.vue'

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    SelectInput,
    TextInput,
    ContactInfo,
    StepsIndicator,
    Input,
  },
  layout: Layout,
  remember: 'form',
  props: ['disciplines', 'customForm', 'update_license'],

  data() {
    return {
      errors: [],
      form: { form_id: this.update_license?.form.id },
      forms: null,
      currentStep: 1,
      form_data: this.update_license?.form_data || null,
      discipline_id: this.update_license?.form.discipline_id || null,
    }
  },
  mounted() {
    if (!this.update_license) return
    this.onDisciplineSelect()
    this.onFormTypeSelect()
    console.log(this.$data)
  },
  methods: {
    getAllElements(form) {
      if (form.type == 'steps') {
        window.els = form.form.steps.map((s) => s.elements)
        return form.form.steps.map((s) => s.elements)?.flat()
      }
      if (form.type == 'default') {
        return form.form
      }
    },
    generateFieldUsingFormElements(elms) {
      let fields = []
      elms?.forEach((el) => (fields[el.name] = ''))
      return fields
    },
    onFormTypeSelect() {
      const dbForm = this.forms.find((f) => f.id == this.form.form_id) || []
      const elms = dbForm && this.getAllElements(dbForm)
      const generatedFields = this.form_data || this.generateFieldUsingFormElements(elms)
      this.form = this.$inertia.form({
        ...dbForm,
        form_data: generatedFields,
        form_id: dbForm.id,
      })
    },
    onDisciplineSelect() {
      const dbDiscipline = this.$props.disciplines.find((f) => f.id == this.discipline_id)
      this.forms = dbDiscipline?.forms
    },

    // step form funcs
    totalSteps() {
      if (!this.form) return '0'
      return this.form.form?.steps?.length || 0
    },
    isFirstStep() {
      if (!this.form) return false
      return this.currentStep === 1
    },
    isLastStep() {
      // if (!this.form) return false
      return this.currentStep === this.totalSteps()
    },
    onStepClick(step) {
      this.currentStep = step
    },
    previousStep() {
      if (this.isFirstStep()) return
      this.currentStep--
    },
    nextStep() {
      if (this.isLastStep()) return
      this.currentStep++
    },
    onStepClick(step) {
      this.currentStep = step
    },

    store(draft) {
      if (!this.form.form_id) return (this.$page.props.flash.error = 'Please select license type first.')
      if (this.update_license) {
        return this.form
          .transform((d) => ({ ...d, form_data: { ...this.form.form_data }, _method: 'put' }))
          .post(this.route('licenses.update', { license: this.update_license.id, _query: { draft } }), {
            onError: (err) => console.log(err),
          })
      }
      this.form
        .transform((d) => ({ ...d, form_data: { ...this.form.form_data } }))
        .post(this.route('licenses.store', { _query: { draft } }), {
          onError: (err) => console.log(err),
        })
    },

    upload(event, question) {
      question.uploadPercentage = 0

      let formData = new FormData()
      formData.append('file', event.target.files[0])
      formData.append('question_id', question.id)

      axios
        .post('/attachments', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: function (progressEvent) {
            question.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
          }.bind(this),
        })
        .then((response) => {
          this.form.questions = this.form.questions.map((q) => (q.id == question.id ? { ...question, answer: response.data.id, attachment: response.data } : q))
        })
        .catch((error) => { })
    },

    removeAttachment(question) {
      this.form.questions = this.form.questions.map((q) => (q.id == question.id ? { ...question, answer: null, attachment: null } : q))
      axios
        .delete(`/attachments/${question.attachment.id}`)
        .then((res) => res)
        .catch((err) => err)
    },

    getError(index, key) {
      if (this.form.errors['questions.' + index + '.answer']) {
        return this.form.errors['questions.' + index + '.answer']
      }

      if (key && this.form.errors[key]) {
        return this.form.errors[key]
      }

      return null
    },

    showRelatedQuestion(question) {
      if (!question.related_question_id) return true

      let relatedQuestion = this.form.questions.find((q) => q.id == question.related_question_id)

      return relatedQuestion && relatedQuestion.answer == question.related_answer
    },
  },
}
</script>
