<template>
  <div>
    <Head title="Create Role" />
    <h1 class="mb-8 text-xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/roles">Roles</Link>
      <span class="text-indigo-400 font-medium">/</span> Create Permission
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="store">
        <div class="bg-white rounded-md shadow overflow-hidden max-w-3xl">
          <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
            <text-input v-model="form.name" :error="form.errors.name" class="pr-6 pb-8 w-full lg:w-1/2" label="Permission Name" />
          </div>

          <div class="mt-10 px-8 py-4 bg-gray-50 border-t border-gray-100 flex justify-end items-center">
            <loading-button :loading="form.processing" class="btn-indigo" type="submit">Submit</loading-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import LoadingButton from '@/Shared/LoadingButton'

export default {
  components: {
    LoadingButton,
    TextInput,
    Head,
    Link,
  },

  layout: Layout,

  remember: 'form',

  data() {
    return {
      form: this.$inertia.form({
        name: null,
      }),
    }
  },
  methods: {
    store() {
      this.form.post('/permissions')
    },
  },
}
</script>
