<template>
  <div>
    <modal :show="showModal">
      <div class="p-4 text-lg font-semibold border-b border-gray-100">Are you sure want to Decline the Application?</div>

      <div class="p-4">
        <textarea-input v-model="form.reason" class="w-full" :error="form.errors.reason" label="Decline Reason" />
      </div>

      <div class="p-4 bg-gray-100 flex justify-end gap-2">
        <button class="btn bg-green-600 hover:bg-green-700 rounded px-4 py-2 text-white font-semibold text-sm" @click="showModal = false">Cancel</button>
        <button class="btn bg-red-600 hover:bg-red-700 rounded px-4 py-2 text-white font-semibold text-sm" @click="decline">Decline</button>
      </div>
    </modal>

    <Head title="licenses" />
    <div class="flex justify-between">
      <h1 class="text-2xl font-bold">Details</h1>
      <button v-if="isExpired(license) == 'Expired'" class="btn bg-green-600 rounded px-4 py-2 text-white font-semibold text-sm">Renew</button>
    </div>

    <status-bar v-if="license.status != 'License Issued'" :license="license" />

    <div class="bg-white overflow-x-auto mt-4">
      <div class="flex justify-between bg-gray-200">
        <h3 class="p-4 font-semibold text-medium">License Details</h3>
        <div class="flex">
          <h3 v-if="license.number" class="p-4 font-semibold text-medium bg-green-600 text-white">{{ license.number }}</h3>
          <h3 v-if="license.number" class="p-4 font-semibold text-medium bg-orange-500 text-white">{{ isExpired(license) }}</h3>
        </div>
      </div>
      <hr />
      <div class="grid grid-cols-4">
        <div class="col-span-3">
          <div class="grid grid-cols-3 gap-6 p-4">
            <div class="space-y-2">
              <div class="font-semibold">Business Type:</div>
              <div>{{ license.business_type == 1 ? 'Individual' : 'Business' }}</div>
            </div>
            <div class="space-y-2">
              <div class="font-semibold">Discipline:</div>
              <div>{{ license.discipline.name }}</div>
            </div>
            <div class="space-y-2">
              <div class="font-semibold">License Type:</div>
              <div>{{ license.license_type.name }}</div>
            </div>
            <div class="space-y-2">
              <div class="font-semibold">Social Security Number:</div>
              <div>{{ license.social_security_number }}</div>
            </div>
            <div class="space-y-2">
              <div class="font-semibold">Fein Number:</div>
              <div>{{ license.fein_number }}</div>
            </div>
            <div class="space-y-2">
              <div class="font-semibold">Date of Birth:</div>
              <div>{{ license.dob }}</div>
            </div>
          </div>
        </div>
        <div class="flex justify-end items-center">
          <img v-if="license.user.photo" class="block ml-4 w-32 h-32 rounded-lg" :src="license.user.photo" />
        </div>
      </div>

      <h3 class="p-4 font-semibold text-medium bg-gray-200">Business Details</h3>
      <hr />
      <div class="grid grid-cols-4 gap-4 p-4">
        <div class="space-y-2">
          <div class="font-semibold">Name:</div>
          <div>{{ license.licensee_details.name }}</div>
        </div>
        <div class="space-y-2">
          <div class="font-semibold">Email:</div>
          <div>{{ license.licensee_details.email }}</div>
        </div>
        <div class="space-y-2">
          <div class="font-semibold">Phone:</div>
          <div>{{ license.licensee_details.phone }}</div>
        </div>
        <div></div>
        <div class="space-y-2">
          <div class="font-semibold">State:</div>
          <div>{{ license.licensee_details.state }}</div>
        </div>
        <div class="space-y-2">
          <div class="font-semibold">City:</div>
          <div>{{ license.licensee_details.city }}</div>
        </div>
        <div class="space-y-2">
          <div class="font-semibold">Zip Code:</div>
          <div>{{ license.licensee_details.zip_code }}</div>
        </div>
      </div>

      <div v-if="license.business_details">
        <h3 class="p-4 font-semibold text-medium bg-gray-200">Business Holder Details</h3>
        <hr />
        <div class="grid grid-cols-4 gap-4 p-4">
          <div class="space-y-2">
            <div class="font-semibold">Name:</div>
            <div>{{ license.business_details.name }}</div>
          </div>
          <div class="space-y-2">
            <div class="font-semibold">Email:</div>
            <div>{{ license.business_details.email }}</div>
          </div>
          <div class="space-y-2">
            <div class="font-semibold">Phone:</div>
            <div>{{ license.business_details.phone }}</div>
          </div>
          <div></div>
          <div class="space-y-2">
            <div class="font-semibold">State:</div>
            <div>{{ license.business_details.state }}</div>
          </div>
          <div class="space-y-2">
            <div class="font-semibold">City:</div>
            <div>{{ license.business_details.city }}</div>
          </div>
          <div class="space-y-2">
            <div class="font-semibold">Zip Code:</div>
            <div>{{ license.business_details.zip_code }}</div>
          </div>
        </div>
      </div>

      <h3 class="p-4 font-semibold text-medium bg-gray-200">Other Details</h3>
      <hr />
      <div class="grid grid-cols-3 gap-6 p-4">
        <div v-for="question in license.questions" :key="question.id" class="space-y-2">
          <div class="font-semibold">{{ question.title }}</div>
          <div v-if="question.type !== 6">{{ renderAnswer(question) }}</div>
          <div v-else>
            <button class="cursor-pointer bg-indigo-500 hover:bg-indigo-500 text-white flex justify-center items-center py-2 px-4 text-sm rounded" @click="download(question.pivot.answer)">Download</button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end gap-2 py-4">
      <loading-button v-if="!['Declined', 'Draft', 'Pay Application'].includes(license.status)" :loding="loading" class="btn bg-yellow-500 hover:bg-yellow-600 rounded px-4 py-2 text-white font-semibold" @click="showModal = true">Reject</loading-button>
      <loading-button v-if="!['License Issued', 'Draft', 'Pay Application'].includes(license.status)" :loading="loading" class="btn-indigo" @click="approve">Approve</loading-button>
      <loading-button v-if="license.status == 'Draft'" :loading="loading" class="btn-indigo" @click="edit">Edit Application</loading-button>
      <loading-button v-if="license.status == 'Pay Application'" :loading="loading" class="btn-indigo" @click="submit">Submit Application</loading-button>
      <Link v-if="license.status == 'Draft'" class="btn-indigo" :href="`/licenses/${license.id}/payment`"> Pay Application Fee </Link>
      <Link v-if="license.status == 'License Issued'" class="btn-indigo" :href="`/licenses/${license.id}/print`"> Print </Link>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import axios from 'axios'
import LoadingButton from '@/Shared/LoadingButton'
import TextareaInput from '@/Shared/TextareaInput'
import Modal from '@/Shared/Modal'
import StatusBar from '@/Shared/License/StatusBar'
import { Inertia } from '@inertiajs/inertia'

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    Modal,
    TextareaInput,
    StatusBar,
  },
  layout: Layout,
  props: {
    license: Object,
  },
  data() {
    return {
      downloading: false,
      loading: false,
      showModal: false,
      form: this.$inertia.form({ reason: '' }),
    }
  },
  mounted() {},

  methods: {
    renderAnswer(question) {
      // File
      if (question.type == 6) return

      // Text, Number, Date
      if ([1, 2, 7].includes(question.type)) {
        return question.pivot.answer
      }

      // Checkbox
      if (question.type == 4) {
        let answerArray = JSON.parse(question.pivot.answer)

        const answerText = question.options
          .filter((o) => answerArray.includes(o.id))
          .map((o) => o.text)
          .join(', ')

        return answerText
      }

      // Radio button, dropdown
      if ([5, 3].includes(question.type)) {
        const option = question.options.find((o) => o.id == +question.pivot.answer)

        return option && option.text
      }
    },

    download(id) {
      this.downloading = true

      axios({
        url: `/attachments/${id}`,
        method: 'GET',
        responseType: 'blob', // important
      })
        .then((response) => {
          let filename = response.headers['content-disposition'].split('filename=')[1].split('.')[0]
          let extension = response.headers['content-disposition'].split('.')[1].split(';')[0]

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${filename}.${extension}`) //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {})
        .finally(() => (this.downloading = false))
    },

    approve() {
      Inertia.patch(`/licenses/${this.license.id}/approval`)
    },

    decline() {
      this.form.patch(`/licenses/${this.license.id}/rejection`, {
        onSuccess: () => (this.showModal = false),
      })
    },

    submit() {
      Inertia.patch(`/licenses/${this.license.id}/submission`)
    },

    isExpired(license) {
      if (license.approved_at) {
        const approvedDate = new Date(license.approved_at)
        const today = new Date()
        const diffTime = Math.abs(today - approvedDate)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        if (diffDays > 60) {
          return 'Expired'
        } else {
          return 'Active'
        }
      }
    },
  },
}
</script>
