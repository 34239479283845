<template>
  <div>

    <Head title="Application Fee Payment" v-if="!withoutHead" />
    <h1 class="mb-8 text-2xl font-bold">License Fee Payment</h1>
    <status-bar :license="license" />
    <div class="container max-w-7xl mx-auto flex justify-center p-8">
      <div>
        <div class="text-lg">To process your license application, you should pay the application fee:</div>
        <payment-form />
        <div class="mt-4">
          <loading-button class="btn-indigo justify-center w-full text-center py-4" @click="pay">Pay
            $200</loading-button>
        </div>
        <div class="mt-8 py-4 grid grid-cols-3">
          <div class="border-b border-gray-300" />
          <div class="text-center">or pay with Paypal</div>
          <div class="border-b border- border-gray-300" />
        </div>
        <div id="paypal-box" ref="paypal-box" class="mt-8" />
      </div>
    </div>
  </div>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import PaymentForm from '@/Shared/License/PaymentForm'
import LoadingButton from '@/Shared/LoadingButton'
import { loadScript } from '@paypal/paypal-js'
import { Inertia } from '@inertiajs/inertia'
import StatusBar from '@/Shared/License/StatusBar'

export default {
  components: {
    Head,
    PaymentForm,
    LoadingButton,
    StatusBar,
  },
  layout: Layout,

  props: {
    license: Object,
    withoutHead: Boolean,
  },

  data() {
    return {
      paypal: null,
    }
  },

  mounted() {
    if (!this.paypal) {
      this.loadPaypalScript()
    }
  },

  methods: {
    async loadPaypalScript() {
      try {
        this.paypal = await loadScript({ 'client-id': 'AXSRMtPMAWDJlwcRv9froT3Mlm-Is-44fOFYmJRxugJVWowGrzqOfIyMVexDRZKVH2iES8w4RJt8yMod' })

        if (this.paypal) {
          try {
            await this.paypal
              .Buttons({
                createOrder: (data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          value: '200',
                        },
                      },
                    ],
                  })
                },
                onApprove: (data, actions) => {
                  return actions.order.capture().then((details) => {
                    this.pay()
                  })
                },
              })
              .render('#paypal-box')
          } catch (error) {
            console.error('failed to render the PayPal Buttons', error)
          }
        }
      } catch (error) {
        console.error(error)
      }
    },

    pay() {
      Inertia.post(`/licenses/${this.license.id}/payment`)
    },
  },
}
</script>
