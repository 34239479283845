<template>
  <div>
    <Head title="Show License" />
    <div class="flex justify-between mb-8">
      <div class="flex">
        <h1 class="text-xl font-bold">
          <Link class="text-indigo-400 hover:text-indigo-600" href="/licenses">Licenses</Link>
          <span class="text-indigo-400 font-medium">/</span>
          {{ license.user.first_name }} {{ license.user.last_name }}
        </h1>
        <img v-if="license.user.photo" class="block ml-4 w-8 h-8 rounded-full" :src="license.user.photo" />
      </div>
      <Link class="btn-indigo" :href="`/licenses/${license.id}`">
        <span><span class="fas fa-eye fa-sm mr-2" />Show License Application</span>
      </Link>
    </div>

    <div class="grid grid-cols-5 gap-4 bg-gray-50 rounded-lg py-4 px-2">
      <div class="flex flex-col items-center col-span-1">
        <img class="w-24 h-24 rounded-lg" :src="license.user.photo ? license.user.photo : `https://ui-avatars.com/api/?name=${license.user.first_name}+${license.user.last_name}&color=ffffff&background=2f365f`" width="32" height="32" alt="User" />
        <div class="font-semibold pt-3">{{ license.user.first_name }} {{ license.user.last_name }}</div>
      </div>
      <div class="col-span-2 space-y-4">
        <div>
          <b class="font-semibold">License Number:</b> <span class="bg-green-500 p-1 rounded-sm text-white font-semibold text-sm">{{ license.number }} </span>
        </div>
        <div><b class="font-semibold">Discipline:</b> {{ license.form.discipline.name }}</div>
        <div><b class="font-semibold">License Name:</b> {{ license.form.name }}</div>
        <div><b class="font-semibold">Expiration Date:</b> {{ license.expiryDate }}</div>
      </div>
      <div class="col-span-2 space-y-4">
        <div>
          <b class="font-semibold mr-2">License Status:</b>
          <span v-if="isExpired(license) == 'Expired'" class="bg-orange-500 p-1 rounded-sm text-white font-semibold text-sm">{{ isExpired(license) }} </span>
          <span v-if="isExpired(license) == 'Active'" class="bg-green-500 p-1 rounded-sm text-white font-semibold text-sm">{{ isExpired(license) }} </span>
        </div>
        <div><b class="font-semibold">Initial Issued Date:</b> {{ new Date(license.approved_at).toLocaleString() }}</div>
        <div><b class="font-semibold">Effective Date:</b> {{ new Date(license.approved_at).toLocaleString() }}</div>
        <div><b class="font-semibold">Affiliated Agency Name:</b> None</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'

export default {
  components: {
    Head,
    Link,
  },
  layout: Layout,
  props: {
    license: Object,
  },
  data() {
    return {}
  },
  methods: {
    isExpired(license) {
      if (license.approved_at) {
        const approvedDate = new Date(license.approved_at)
        const today = new Date()
        const diffTime = Math.abs(today - approvedDate)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        if (diffDays > 60) {
          return 'Expired'
        } else {
          return 'Active'
        }
      }
    },
  },
}
</script>
