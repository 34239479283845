<template>
    <div class="relative">
        <div class="flex flex-col w-full mt-1 z-50">
            <label for="" class="form-label"> {{ label }} </label>
            <input
                class="form-input"
                type="search"
                :placeholder="placeholder"
                @input="input"
                v-model="search"
                ref="search"
            />
            <div class="max-h-32 overflow-y-scroll bg-white border border-gray-200" v-if="showDropdown">
                <a v-for="option in options" :key="option.id" 
                    class="flex items-center h-10 px-3 text-sm hover:bg-gray-200 border-b border-gray-200"
                    href="javascript:void(0)"
                    @click="select(option)">
                    {{ option[objectKey] }} 
                    <span v-if="option.forms.length > 0">
                        &nbsp; ({{ option.forms.map(f => f.name).join(', ') }})
                    </span>
                </a
                >
            </div>

            <div class="max-h-32 overflow-y-scroll bg-white border border-gray-200" v-if="search.length > 0 && options.length == 0">
                <a 
                    class="flex items-center h-10 px-3 text-sm hover:bg-gray-200 border-b border-gray-200"
                    href="javascript:void(0)">
                    No data found</a
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        /**
         * key to look for in option Object
         * @default 'key'
         * @type {String}
         */
        objectKey: {
            type: String,
            default: 'title'
        },
        /**
         * String to show when pointing to an option
         * @default 'Search'
         * @type {String}
         */
        placeholder: {
            type: String,
            default: 'Search...'
        },
        /**
         * String to show when pointing to an option
         * @default ''
         * @type {String}
         */
        label: {
            type: String,
            default: ''
        },
        /**
         * Array of available options: Objects, Strings or Integers.
         * If `labal` prop is passed, key will equal option['key']
         * @type {Array}
         */
        options: {
            type: Array,
            required: true
        },
        /**
         *
         *
         */
        value: {
            type: Object,
            validator: value => {
                return Object.keys(value).length >= 0;
            }
        }
    },
    mounted() {
        this.listData = this.options;
        this.selected = this.value;

        document.addEventListener('click', this.handleClickOutside);
        document.addEventListener('keyup', this.handleClickOutside);

        if (this.options.length > 0) {
            this.showDropdown = true;
        }
    },

    destroyed() {
        document.removeEventListener('click', this.handleClickOutside);
        document.removeEventListener('keyup', this.handleClickOutside);
    },

    watch: {
        options() {
            if (this.options.length > 0) {
                this.showDropdown = true;
            } else {
                this.showDropdown = false;
            }
        }
    },

    data() {
        return {
            loading: true,
            search: '',
            showDropdown: false,
            timeout: null
        };
    },

    methods: {
        handleClickOutside(e) {
            if (this.$el.contains(e.target) || e.target.id === this.inputId) {
                return;
            }

            this.showDropdown = false;
            this.search = '';
        },

        open() {
            this.showDropdown = true;
        },

        close() {
            this.showDropdown = false;
        },

        select(item) {
            this.$emit('onSelect', item);
            this.close();
            this.search = '';
            this.$refs.search.focus();
        },

        input(event) {
            this.$emit('onInput', event.target.value);

            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.$emit('onSearch', event.target.value);
            }, 500);
        }
    }
};
</script>
