<template>
  <div>
    <Head title="Continues Education" />

    <h3 class="mb-8 text-2xl font-bold">Continued Education Verification Center</h3>

    <div class="w-full mt-4 flex justify-center gap-4">
      <text-input v-model="number" label="License Number" type="search" class="w-1/2" placeholder="Please enter license number here..." @input="search" />
    </div>
    <div v-if="licenses.length" class="bg-white rounded-md shadow overflow-x-auto mt-4">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold border-b">
          <th class="py-2 pl-3">Name</th>
          <th class="py-2 pl-2">Date of Birth</th>
          <th class="py-2 pl-2">Number</th>
          <th class="py-2 pl-2">Discipline</th>
          <th class="py-2 pl-2">Type</th>
          <th class="py-2 pl-2">Status</th>
        </tr>
        <tr v-for="license in licenses" :key="license.id" class="hover:bg-gray-100 focus-within:bg-gray-100 text-sm cursor-pointer" @click="setLicense(license)">
          <td class="border-t">
            <Link class="flex items-center py-1 pl-2" :href="`/licenses/${license.id}`" tabindex="-1">
              <img v-if="license.user.photo" class="block mr-1 w-5 h-5 rounded-full" :src="license.user.photo" />
              <span>{{ license.user.first_name }} {{ license.user.last_name }}</span>
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-2 py-1 focus:text-indigo-500" :href="`/licenses/${license.id}`">
              {{ license.user.dob }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-2 py-1" :href="`/licenses/${license.id}`" tabindex="-1">
              <span class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-green-600 rounded-full">{{ license.number }}</span>
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-2 py-1" :href="`/licenses/${license.id}`" tabindex="-1">
              <span class="bg-gray-200 p-1 rounded-sm">{{ license.form.discipline.name }}</span>
            </Link>
          </td>

          <td class="border-t">
            <Link class="flex items-center px-2 py-1" :href="`/licenses/${license.id}`" tabindex="-1">
              <span class="bg-gray-200 p-1 rounded-sm">{{ license.form.business_type == '0' ? 'Business' : 'Individual' }}</span>
            </Link>
          </td>

          <td class="border-t">
            <Link class="flex items-center px-2 py-1" :href="`/licenses/${license.id}`" tabindex="-1">
              <span v-if="isExpired(license) == 'Expired'" class="bg-orange-500 p-1 rounded-sm text-white font-semibold text-sm">{{ isExpired(license) }} </span>
              <span v-if="isExpired(license) == 'Active'" class="bg-green-500 p-1 rounded-sm text-white font-semibold text-sm">{{ isExpired(license) }} </span>
            </Link>
          </td>

          <td class="w-px border-t">
            <Link class="flex items-center px-2" :href="`/licenses/${license.id}`" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </Link>
          </td>
        </tr>
      </table>
    </div>
    <div v-if="number && licenses.length == 0" class="w-full flex justify-center">
      <p class="px-2 py-1 mt-4 text-xs font-bold leading-none text-white bg-yellow-500 rounded-full">Loading ...No license found.</p>
    </div>

    <div v-if="license && license.id" class="space-y-8 mt-8 bg-white rounded-md shadow overflow-hidden p-4">
      <div class="grid grid-cols-5 gap-4 bg-gray-50 rounded-lg py-4 px-2">
        <div class="flex flex-col items-center col-span-1">
          <img class="w-24 h-24 rounded-lg" :src="license.user.photo ? license.user.photo : `https://ui-avatars.com/api/?name=${license.user.first_name}+${license.user.last_name}&color=ffffff&background=2f365f`" width="32" height="32" alt="User" />
          <div class="font-semibold pt-3">{{ license.user.first_name }} {{ license.user.last_name }}</div>
        </div>
        <div class="col-span-2 space-y-4">
          <div>
            <b class="font-semibold">License Number:</b> <span class="bg-green-500 p-1 rounded-sm text-white font-semibold">{{ license.number }} </span>
          </div>
          <div><b class="font-semibold">Discipline:</b> {{ license.form.discipline.name }}</div>
          <div><b class="font-semibold">License Name:</b> {{ license.form.name }}</div>
          <div><b class="font-semibold">Expiration Date:</b> {{ license.expiryDate }}</div>
        </div>
        <div class="col-span-2 space-y-4">
          <div>
            <b class="font-semibold mr-2">License Status:</b>
            <span v-if="isExpired(license) == 'Expired'" class="bg-orange-500 p-1 rounded-sm text-white font-semibold text-sm">{{ isExpired(license) }} </span>
            <span v-if="isExpired(license) == 'Active'" class="bg-green-500 p-1 rounded-sm text-white font-semibold text-sm">{{ isExpired(license) }} </span>
          </div>
          <div><b class="font-semibold">Initial Issued Date:</b> {{ new Date(license.approved_at).toLocaleString() }}</div>
          <div><b class="font-semibold">Effective Date:</b> {{ new Date(license.approved_at).toLocaleString() }}</div>
          <div><b class="font-semibold">Affiliated Agency Name:</b> None</div>
        </div>
      </div>
      <div class="my-4 text-lg font-semibold">Please answer the below questions:</div>
      <div class="grid grid-cols-2">
        <div>
          <div>Has this person completed the Continued Education?</div>
          <label class="flex items-center mt-2">
            <input type="radio" value="yes" class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 mx-1" />
            Yes
          </label>
          <label class="flex items-center mt-2">
            <input type="radio" value="yes" class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 mx-1" />
            No
          </label>
        </div>

        <div>
          <div>Has this person passed the required score?</div>
          <label class="flex items-center mt-2">
            <input type="radio" value="yes" class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 mx-1" />
            Yes
          </label>
          <label class="flex items-center mt-2">
            <input type="radio" value="yes" class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 mx-1" />
            No
          </label>
        </div>
      </div>

      <div class="grid grid-cols-2">
        <div>
          <text-input label="Aquired Score" class="w-1/2" type="number" />
        </div>
        <div class="space-y-2">
          <div>Upload certificate:</div>
          <input id="" type="file" name="" />
        </div>
      </div>

      <div class="flex justify-end">
        <loading-button class="btn-indigo" @click="submit">Submit</loading-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import TextInput from '@/Shared/TextInput'
import LoadingButton from '@/Shared/LoadingButton'
import Layout from '@/Shared/Layout'
import { Inertia } from '@inertiajs/inertia'
import { Head } from '@inertiajs/inertia-vue3'

export default {
  components: {
    TextInput,
    LoadingButton,
    Head,
  },
  layout: Layout,

  data() {
    return {
      licenses: [],
      license: {},
      loading: false,
      number: null,
      timeout: null,
    }
  },

  methods: {
    search() {
      if (this.timeout) clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        if (!this.number.length) {
          this.licenses = []
          this.license = null
          return
        }

        this.loading = true
        axios
          .get(`/licenses/search?number=${this.number}`)
          .then((res) => (this.licenses = res.data))
          .catch((err) => err)
          .finally(() => (this.loading = false))
      }, 500)
    },

    submit() {
      Inertia.post('/ce', {}, { preserveState: false })
    },

    setLicense(license) {
      this.license = license
    },

    isExpired(license) {
      if (license.approved_at) {
        const approvedDate = new Date(license.approved_at)
        const today = new Date()
        const diffTime = Math.abs(today - approvedDate)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        if (diffDays > 60) {
          return 'Expired'
        } else {
          return 'Active'
        }
      }
    },
  },
}
</script>