<template>
  <div class="bg-white rounded mt-4 shadow-lg p-8">
    <div class="grid grid-cols-2 gap-4">
      <div class="col-span-2">
        <label class="text-xs font-semibold" for="cardNumber">Card Holder Name</label>
        <input class="flex items-center h-10 border mt-1 rounded px-4 w-full text-sm" type="text" placeholder="JOHN DOE" />
      </div>
      <div class="col-span-2">
        <label class="text-xs font-semibold" for="cardNumber">Card number</label>
        <input class="flex items-center h-10 border mt-1 rounded px-4 w-full text-sm" type="text" placeholder="0000 0000 0000 0000" />
      </div>
      <div class="">
        <label class="text-xs font-semibold" for="cardNumber">Expiry Date</label>
        <input class="flex items-center h-10 border mt-1 rounded px-4 w-full text-sm" type="text" placeholder="MM/YY" />
      </div>
      <div class="">
        <label class="text-xs font-semibold" for="cardNumber">CVC/CVV</label>
        <input class="flex items-center h-10 border mt-1 rounded px-4 w-full text-sm" type="password" placeholder="..." />
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style>
</style>