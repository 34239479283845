<template>
  <div class="notification ml-2">
    <a href="#">
      <div class="notBtn" href="#">
        <!--Number supports double digets and automaticly hides itself when there is nothing between divs -->
        <div class="number">
          <span class="text-sm">2</span>
        </div>
        <i class="fas fa-bell" />
        <div class="box">
          <div class="display">
            <div class="cont">
              <!-- Fold this div and try deleting evrything inbetween -->
              <div class="sec new">
                <a href="#">
                  <div class="profCont">
                    <img class="profile" src="https://c1.staticflickr.com/5/4007/4626436851_5629a97f30_b.jpg" />
                  </div>
                  <div class="txt">Your application has been approved.</div>
                  <div class="txt sub">11/7 - 2:30 pm</div>
                </a>
              </div>
              <div class="sec new">
                <a href="#">
                  <div class="profCont">
                    <img class="profile" src="https://obamawhitehouse.archives.gov/sites/obamawhitehouse.archives.gov/files/styles/person_medium_photo/public/person-photo/amanda_lucidon22.jpg?itok=JFPi8OFJ" />
                  </div>
                  <div class="txt">Your application is in under review stage</div>
                  <div class="txt sub">11/7 - 2:13 pm</div>
                </a>
              </div>
              <div class="sec">
                <a href="#">
                  <div class="profCont">
                    <img class="profile" src="https://c1.staticflickr.com/4/3725/10214643804_75c0b6eeab_b.jpg" />
                  </div>
                  <div class="txt">Your application has been declined</div>
                  <div class="txt sub">11/6 - 9:35 pm</div>
                </a>
              </div>

              <div class="sec">
                <a href="#">
                  <div class="profCont">
                    <img class="profile" src="https://upload.wikimedia.org/wikipedia/commons/5/52/NG_headshot_white_shirt_square_Jan18.jpg" />
                  </div>
                  <div class="txt">Admin sent you a notification.</div>
                  <div class="txt sub">11/6 - 10:37 am</div>
                </a>
              </div>
              <div class="sec">
                <a href="#">
                  <div class="profCont">
                    <img class="profile" src="https://upload.wikimedia.org/wikipedia/commons/d/dd/Pat-headshot-square.jpg" />
                  </div>
                  <div class="txt">Your application has been submited.</div>
                  <div class="txt sub">11/5 - 7:30 pm</div>
                </a>
              </div>
              <div class="sec">
                <a href="#">
                  <div class="profCont">
                    <img class="profile" src="https://c1.staticflickr.com/4/3725/10214643804_75c0b6eeab_b.jpg" />
                  </div>
                  <div class="txt">Your license is expiring soon. check it here.</div>
                  <div class="txt sub">11/6 - 4:04 pm</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<style scoped>
a {
  text-decoration: none;
  color: black;
}

a:visited {
  color: black;
}

.box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 5px;
}

.box::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.box::-webkit-scrollbar-thumb {
  background-color: gray;
  border: 2px solid gray;
  border-radius: 5px;
}

.icons {
  display: inline;
  float: right;
}

.notification {
  position: relative;
  display: inline-block;
}

.number {
  height: 22px;
  width: 22px;
  background-color: #d63031;
  border-radius: 20px;
  color: white;
  text-align: center;
  position: absolute;
  top: 8px;
  left: 12px;
  /* padding: 3px; */
  border-style: solid;
  border-width: 2px;
}

.number:empty {
  display: none;
}

.notBtn {
  transition: 0.5s;
  cursor: pointer;
}

.fas {
  font-size: 17pt;
  padding-bottom: 10px;
  color: black;
  /* margin-right: 40px; */
  /* margin-left: 40px; */
}

.box {
  width: 320px;
  height: 0px;
  border-radius: 10px;
  transition: 0.5s;
  position: absolute;
  overflow-y: scroll;
  padding: 0px;
  left: -300px;
  margin-top: 10px;
  background-color: white;
  -webkit-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.1);
  cursor: context-menu;
}

.fas:hover {
  color: #d63031;
}

.notBtn:hover > .box {
  height: 60vh;
}

.content {
  padding: 20px;
  color: black;
  vertical-align: middle;
  text-align: left;
}

.gry {
  background-color: #f4f4f4;
}

.top {
  color: black;
  padding: 10px;
}

.display {
  position: relative;
}

.cont {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
}

.cont:empty {
  display: none;
}

.stick {
  text-align: center;
  display: block;
  font-size: 50pt;
  padding-top: 70px;
  padding-left: 80px;
}

.stick:hover {
  color: black;
}

.cent {
  text-align: center;
  display: block;
}

.sec {
  padding: 10px 10px;
  background-color: white;
  transition: 0.5s;
}

.profCont {
  padding-left: 5px;
}

.profile {
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
  width: 50px;
  float: left;
}

.txt {
  vertical-align: top;
  font-size: 1rem;
  padding: 5px 10px 0px 70px;
}

.sub {
  font-size: 1rem;
  color: grey;
}

.new {
  border-style: none none solid none;
  border-color: red;
}

.sec:hover {
  background-color: #bfbfbf;
}
</style>