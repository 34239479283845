<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="mb-2 font-semibold text-lg"><span class="fas fa-credit-card mr-2 fa-sm" />Payment History</h1>
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="p-4">Amount</th>
          <th class="p-4">Status</th>
          <th class="p-4">Date</th>
          <th class="p-4">Reason</th>
        </tr>
        <tr class="hover:bg-gray-100 focus-within:bg-gray-100 text-sm">
          <td class="border-t">
            <span class="flex items-center px-4 py-2" tabindex="-1"> $200.00 </span>
          </td>
          <td class="border-t">
            <span class="flex items-center px-4 py-2" tabindex="-1">
              <span class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-green-600 rounded-sm">Paid</span>
            </span>
          </td>
          <td class="border-t">
            <span class="flex items-center px-4 py-2" tabindex="-1"> 2023-01-10 </span>
          </td>
          <td class="border-t">
            <span class="flex items-center px-4 py-2" tabindex="-1"> Application Fee </span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
  
<script>
import Layout from '@/Shared/Layout'

export default {
  metaInfo: { title: 'Payment History' },

  layout: Layout,
}
</script>
  