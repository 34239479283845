<template>
  <div>
    <Head title="Create License Workflow" />
    <FormBuilder v-bind="$props" />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import FormBuilder from '@/Shared/FormBuilder'
import Bell from '@/Shared/Bell'

export default {
  components: {
    Head,
    Link,

    FormBuilder,
    Bell,
  },

  layout: Layout,
  props: ['update_form', 'forms', 'disciplines', 'users'],

  remember: 'form',

  methods: {},
}
</script>
