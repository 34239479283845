<template>
  <div>
    <modal :show="showModal">
      <create @associated="onAssociated" />
      <div class="p-4 bg-gray-100 flex justify-end gap-2">
        <button class="btn bg-green-600 hover:bg-green-700 rounded px-4 py-2 text-white font-semibold" @click="showModal = false">Cancel</button>
      </div>
    </modal>
    <div class="px-8 py-4">
      <div class="flex items-between py-4">
        <h3 class="font-semibold text-lg">Associations</h3>
        <loading-button class="btn-indigo ml-auto" @click="showModal = true">Request New</loading-button>
      </div>

      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="py-2">Name</th>
          <th class="py-2">Date of Birth</th>
          <th class="py-2">Number</th>
          <th class="py-2">Discipline</th>
          <th class="py-2">Type</th>
        </tr>
        <tr v-for="association in associations" :key="association.id" class="hover:bg-gray-100 focus-within:bg-gray-100 text-sm">
          <td class="border-t">
            <Link class="flex items-center py-1" :href="`/licenses/${association.license.id}`" tabindex="-1">
              <img v-if="association.license.user.photo" class="block mr-1 w-5 h-5 rounded-full" :src="association.license.user.photo" />
              {{ association.license.user.first_name }} {{ association.license.user.last_name }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center py-1 focus:text-indigo-500" :href="`/licenses/${association.license.id}`">
              {{ association.license.user.dob }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center py-1" :href="`/licenses/${association.license.id}`" tabindex="-1">
              <span class="bg-green-500 p-1 rounded-sm text-white font-semibold">{{ association.license.number }} </span>
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center py-1" :href="`/licenses/${association.license.id}`" tabindex="-1">
              {{ association.license.form.discipline.name }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center py-1 focus:text-indigo-500" :href="`/licenses/${association.license.id}`">
              {{ association.license.form.business_type == '0' ? 'Business' : 'Individual' }}
            </Link>
          </td>

          <td class="w-px border-t">
            <Link class="flex items-center" :href="`/licenses/${association.license.id}`" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </Link>
          </td>
        </tr>
        <tr v-if="!associations.length">
          <td class="py-2 border-t text-sm" colspan="4">
            <span class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-orange-500 rounded-full">Associated with no one.</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Modal from '@/Shared/Modal'
import LoadingButton from '@/Shared/LoadingButton'
import Create from '@/Shared/Association/Create'
import axios from 'axios'

export default {
  components: {
    Modal,
    Create,
    LoadingButton,
  },

  data() {
    return {
      showModal: false,
      associations: [],
      loading: false,
    }
  },

  mounted() {
    this.getAssociations()
  },

  methods: {
    async getAssociations() {
      this.loading = true

      try {
        let response = await axios.get('/associations')

        this.associations = response.data
      } catch (error) {
        console.error(error)
      }

      this.loading = false
    },

    onAssociated() {
      this.showModal = false
      this.getAssociations()
    },
  },
}
</script>

<style>
</style>