<template>
  <div>
    <Head title="License Preview" />
    <h1 class="mb-8 text-2xl font-bold">License Preview</h1>
    <div class="container w-full flex justify-center p-8">
      <print-preview :license="license" />
    </div>

    <Vue3Html2pdf ref="html2pdf" pdf-format="a4" :show-layout="false" :preview-modal="false" :enable-download="false" pdf-content-width="100%" :manual-pagination="false" @progress="onProgress($event)" :pdf-quality="2" :paginate-elements-by-height="1400" filename="License" pdf-orientation="portrait" @beforeDownload="beforeDownload($event)" :html-to-pdf-options="options">
      <template v-slot:pdf-content>
        <print-preview :license="license" />
      </template>
    </Vue3Html2pdf>

    <div class="flex justify-end">
      <loading-button class="btn-indigo" :loading="downloading" @click="downloadPDF"><span class="fas fa-download fa-sm mr-2" /> Download</loading-button>
    </div>
  </div>
</template>
    
<script>
import { Head } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import PaymentForm from '@/Shared/License/PaymentForm'
import LoadingButton from '@/Shared/LoadingButton'
import PrintPreview from '@/Shared/License/PrintPreview'
import Vue3Html2pdf from 'vue3-html2pdf'

export default {
  components: {
    Head,
    PaymentForm,
    LoadingButton,
    PrintPreview,
    Vue3Html2pdf,
  },
  layout: Layout,
  props: ['license'],

  data() {
    return {
      progress: 0,
      downloading: false,
      options: {
        margin: [10, 10, 10, 10],
        filename: 'License',
        jsPDF: {
          format: 'a5',
        },
        html2canvas: {
          useCORS: true,
          scale: 4, // resolution
        },
      },
    }
  },

  mounted() {},

  methods: {
    downloadPDF() {
      this.$nextTick(() => {
        this.$refs.html2pdf.generatePdf()
      })
    },

    downloadFinished() {
      this.downloading = false
    },

    onProgress(progress) {
      this.progress = progress
    },

    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get('pdf')
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages()
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i)
            pdf.setFontSize(10)
            pdf.setTextColor(150)
            pdf.text(`License`, pdf.internal.pageSize.getWidth() * 0.5 - 22.5, pdf.internal.pageSize.getHeight() - 4)
          }
        })
        .save()

      this.$nextTick(() => {
        this.downloadFinished()
      })
    },
  },
}
</script>
    