<script setup>
import { onMounted, ref, computed } from 'vue';
import { v4 } from 'uuid';
import { inputTypes, conditionInputTypes, selectInputTypes, textareaTypes } from '@/formElements';

const props = defineProps([
    'modelValue',
    "label",
    "helper",
    'error',
    'type',
    'options'
]);
const labelClasses = computed(() =>
    props.error ?
        "formkit-label text-red-700 dark:text-red-500" :
        "formkit-label"
);
const inputClasses = computed(() =>
    props.error ?
        "formkit-input formkit-inner !bg-red-50 border border-red-500 focus:ring-red-500" :
        "formkit-input formkit-inner"
);
const textareaClasses = computed(() =>
    props.error ?
        "formkit-input formkit-inner !bg-red-50 border border-red-500 focus:ring-red-500" :
        "formkit-input formkit-inner"
);
const selectClasses = computed(() =>
    props.error ?
        "formkit-input formkit-inner !bg-red-50 border border-red-500 focus:ring-red-500" :
        "formkit-input formkit-inner");





// const labelClasses = computed(() =>
//     props.error ?
//         "block mb-2 text-sm font-medium text-red-700 dark:text-red-500" :
//         "block mb-2 text-sm font-medium text-gray-900 dark:text-white"
// );
// const inputClasses = computed(() =>
//     props.error ?
//         "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500" :
//         "block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
// );
// const textareaClasses = computed(() =>
//     props.error ?
//         "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500" :
//         "block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
// );
// const checkboxLabelClasses = computed(() =>
//     props.error ?
//         "ml-2 text-sm font-medium text-red-900 dark:text-gray-500" :
//         "ml-2 text-sm font-medium text-gray-400 dark:text-gray-500"
// );
// const checkboxInputClasses = computed(() =>
//     props.error ?
//         "w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" :
//         "w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
// );
// const selectClasses = computed(() =>
//     props.error ?
//         "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500" :
//         "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
// );



const type = ref(props.type || "text");
const input = ref(null);
const id = v4()
onMounted(() => {
    if (input.value?.hasAttribute('autofocus')) {
        input.value.focus();
    }
});
defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <div :key="id">
        <label v-if="!conditionInputTypes.includes(type) && label" :for="id" :class="labelClasses">
            {{ label }}
            <span class="text-red-500" v-if="$attrs.required">*</span>
        </label>
        <template v-if="selectInputTypes.includes(type)">
            <select :value="modelValue" @change="$attrs.change" @input="$emit('update:modelValue', $event.target.value)"
                ref="input" :id="id" v-bind="{ ...$attrs, class: null }" :class="selectClasses">
                <option v-if="$attrs.placeholder" value="" selected v-html="$attrs.placeholder"></option>
                <template v-for="(option, index) in options" :key="v4()">
                    <option :value="option.value" v-text="option.label"></option>
                </template>
            </select>
        </template>

        <template v-if='inputTypes.includes(type)'>
            <input :class="inputClasses" :type="type || 'text'" :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)" ref="input" :id="id"
                v-bind="{ ...$attrs, class: null }" class="" />
        </template>

        <template v-if="textareaTypes.includes(type)">
            <textarea :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" ref="input" :id="id"
                rows="4" :class="textareaClasses" v-bind="{ ...$attrs, class: null }"></textarea>
        </template>
        <p class="mt-2 text-sm text-gray-500 dark:text-gray-400" v-if="helper" v-html="helper"></p>
        <p class="mt-2 text-sm text-red-600 dark:text-red-500" v-if="error" v-html="error"></p>
    </div>
</template>
