<template>
  <div>
    <!-- Sidebar backdrop (mobile only) -->
    <div class="fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200" :class="sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'" aria-hidden="true" />

    <!-- Sidebar -->
    <div id="sidebar" ref="sidebar" class="flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-56 lg:w-56 lg:sidebar-expanded:!w-56 2xl:!w-56 shrink-0 bg-indigo-800 transition-all duration-200 ease-in-out" :class="sidebarOpen ? 'translate-x-0' : '-translate-x-56'">
      <!-- Sidebar header -->
      <div class="bg-gray-200 flex justify-center py-2 shrink-0 relative">
        <!-- Close button -->
        <button ref="trigger" class="lg:hidden text-slate-500 hover:text-slate-400 absolute top-1 left-1" aria-controls="sidebar" :aria-expanded="sidebarOpen" @click.stop="$emit('close-sidebar')">
          <span class="sr-only">Close sidebar</span>
          <svg class="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
          </svg>
        </button>
        <!-- Logo -->
        <Link href="/">
          <logo class="fill-white" width="120" height="28" />
        </Link>
      </div>

      <div class="pt-2">
        <div v-if="userCan('list applications')">
          <div class="cursor-pointer">
            <Link class="group flex items-center py-2 pl-2" href="/">
              <span class="fas fa-tachometer-alt fa-sm mr-2" :class="isUrl('') ? 'text-white font-semibold' : 'text-indigo-400 group-hover:text-white'" />
              <div :class="isUrl('') ? 'text-white font-semibold' : 'text-indigo-300 group-hover:text-white'">Dashboard</div>
            </Link>
          </div>
          <div class="flex items-center bg-indigo-900 py-2 pl-2 mt-2 text-gray-300">
            <span class="fas fa-file fa-sm mr-2" />
            <div>Applications</div>
          </div>

          <div class="ml-6 text-sm cursor-pointer">
            <Link class="group flex items-center py-1" href="/licenses/create">
              <div :class="isUrl('licenses/create') ? 'text-white font-semibold' : 'text-indigo-300 group-hover:text-white'">Apply for a License</div>
            </Link>
          </div>

          <div class="ml-6 text-sm cursor-pointer">
            <Link class="group flex items-center py-1" href="/applications">
              <div :class="isUrl('applications') ? 'text-white font-semibold' : 'text-indigo-300 group-hover:text-white'">All Applications</div>
            </Link>
          </div>

          <div class="flex items-center bg-indigo-900 py-2 pl-2 mt-2 text-gray-300">
            <span class="fas fa-id-card fa-sm mr-2" />
            <div>Licenses</div>
          </div>

          <div class="ml-6 text-sm cursor-pointer">
            <Link class="group flex items-center py-1" href="/licenses">
              <div :class="isUrl('licenses') ? 'text-white font-semibold' : 'text-indigo-300 group-hover:text-white'">All Licenses</div>
            </Link>
          </div>

          <div v-if="userCan('manage applications')" class="ml-6 text-sm cursor-pointer">
            <Link class="group flex items-center py-1" href="/data-change">
              <div :class="isUrl('data-change') ? 'text-white font-semibold' : 'text-indigo-300 group-hover:text-white'">Change Requests</div>
            </Link>
          </div>
        </div>

        <div v-if="userCan('license settings')">
          <div class="flex items-center bg-indigo-900 py-2 pl-2 mt-2 text-gray-300">
            <span class="fas fa-cog fa-sm mr-2" />
            <div>License Settings</div>
          </div>

          <div class="ml-6 text-sm">
            <Link class="group flex items-center py-1" href="/forms">
              <div :class="isUrl('forms') ? 'text-white font-semibold' : 'text-indigo-300 group-hover:text-white'">License Workflows</div>
            </Link>
          </div>

          <div class="ml-6 text-sm cursor-pointer">
            <Link class="group flex items-center py-1" href="/disciplines">
              <div :class="isUrl('disciplines') ? 'text-white font-semibold' : 'text-indigo-300 group-hover:text-white'">License Disciplines</div>
            </Link>
          </div>
        </div>

        <div v-if="userCan('settings')">
          <div class="flex items-center bg-indigo-900 py-2 pl-2 mt-2 text-gray-300">
            <span class="fas fa-cog fa-sm mr-2" />
            <div>System Settings</div>
          </div>

          <div class="ml-6 text-sm cursor-pointer">
            <Link class="group flex items-center py-1" href="/users">
              <div :class="isUrl('users') ? 'text-white font-semibold' : 'text-indigo-300 group-hover:text-white'">Staff Members</div>
            </Link>
          </div>

          <div class="ml-6 text-sm cursor-pointer">
            <Link class="group flex items-center py-1" href="/portal/users">
              <div :class="isUrl('portal/users') ? 'text-white font-semibold' : 'text-indigo-300 group-hover:text-white'">Portal Users</div>
            </Link>
          </div>

          <div class="ml-6 text-sm cursor-pointer">
            <Link class="group flex items-center py-1" href="/roles">
              <div :class="isUrl('roles') ? 'text-white font-semibold' : 'text-indigo-300 group-hover:text-white'">Roles & Permissions</div>
            </Link>
          </div>

          <div class="ml-6 text-sm cursor-pointer">
            <Link class="group flex items-center py-1" href="/activity-logs">
              <div :class="isUrl('activity-logs') ? 'text-white font-semibold' : 'text-indigo-300 group-hover:text-white'">Activity Logs</div>
            </Link>
          </div>

          <div class="ml-6 text-sm cursor-pointer">
            <Link class="group flex items-center py-1" href="/notifications">
              <div :class="isUrl('notifications') ? 'text-white font-semibold' : 'text-indigo-300 group-hover:text-white'">System Notifications</div>
            </Link>
          </div>

          <!-- <div class="ml-6 text-sm cursor-pointer">
            <Link class="group flex items-center py-1" href="/reports">
              <div :class="isUrl('reports') ? 'text-white font-semibold' : 'text-indigo-300 group-hover:text-white'">Report Center</div>
            </Link>
          </div> -->
        </div>

        <div v-if="userCan('CE Institute')">
          <div class="flex items-center bg-indigo-900 py-2 pl-2 mt-2 text-gray-300">
            <span class="fas fa-check fa-sm mr-2" />
            <div>Continued Education</div>
          </div>

          <div v-if="userCan('CE Institute')" class="ml-6 text-sm cursor-pointer">
            <Link class="group flex items-center py-1" href="/ce">
              <div :class="isUrl('ce') ? 'text-white font-semibold' : 'text-indigo-300 group-hover:text-white'">Verify Licenses</div>
            </Link>
          </div>
        </div>
      </div>

      <!-- logout -->
      <div class="flex shrink-0 mr-4 ml-6 text-sm text-gray-300 font-semibold cursor-pointer items-center">
        <!-- <i class="fas fa-hands-helping" />
        <h3 class="font-semibold ml-2 text-sm">Help Center</h3> -->
        <!-- <p>Hi, {{ authUser.user.first_name }}</p>
                <Link class="group flex items-center" href="/logout" method="delete">
          <div :class="isUrl('logout') ? 'text-white font-semibold' : 'text-indigo-300 group-hover:text-white'">
            <span class="fas fa-sign-out-alt mr-2" />
          </div>
                </Link> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3'
import Logo from '@/Shared/Logo'
import { userCan } from '@/utils/Utils'

export default {
  components: {
    Link,
    Logo,
  },

  props: {
    sidebarOpen: Boolean,
  },

  methods: {
    userCan,
    isUrl(url) {
      let currentUrl = this.$page.url.substr(1)

      if (url === '') {
        return currentUrl === ''
      }

      return url === currentUrl
    },
  },
}
</script>
