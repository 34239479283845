<template>
  <div>
    <div class="w-full bg-white rounded-md shadow overflow-hidden mt-4">
      <div class="p-4 grid grid-cols-3 gap-4">
        <text-input v-model="params.number" type="search" class="w-full" label="License Number" placeholder="Enter license number here..." @input="search" />
        <text-input v-model="params.licensee_name" type="search" class="w-full" label="License Name" placeholder="Enter license name here..." @input="search" />
        <text-input type="search" class="w-full" label="License Status" placeholder="Enter license status here..." @input="search" />
        <!-- <text-input type="date" label="From Date" />
        <text-input type="date" label="To Date" /> -->
      </div>
    </div>
    <!-- <div v-if="licenses.length != 0" class="bg-white rounded-md shadow overflow-x-auto mt-4"> -->
    <div v-if="licenses.length != 0" class="overflow-x-auto mt-4">
      <div v-for="license in licenses" :key="license.id">
        <div class="grid grid-cols-5 gap-4 bg-white rounded-lg py-4 px-2 mb-4 mt-6">
          <div class="flex flex-col items-center col-span-1">
            <img class="w-24 h-24 rounded-lg" :src="license.user.photo ? license.user.photo : `https://ui-avatars.com/api/?name=${license.user.first_name}+${license.user.last_name}&color=ffffff&background=2f365f`" width="32" height="32" alt="User" />
            <div class="font-semibold pt-3">{{ license.user.first_name }} {{ license.user.last_name }}</div>
          </div>
          <div class="col-span-2 space-y-4">
            <div>
              <b class="font-semibold">License Number:</b> <span class="p-1 rounded-sm text-white font-semibold text-sm" :class="license.number ? 'bg-green-500' : 'bg-yellow-500'">{{ license.number ? license.number : 'NA' }}</span>
            </div>
            <div><b class="font-semibold">Discipline:</b> {{ license.form.discipline.name }}</div>
            <div><b class="font-semibold">License Name:</b> {{ license.form.name }}</div>
            <div><b class="font-semibold">Expiration Date:</b> {{ license.approved_at ? license.expiryDate : 'NA' }}</div>
          </div>
          <div class="col-span-2 space-y-4">
            <div>
              <b class="font-semibold mr-2">License Status:</b>
              <span v-if="isExpired(license) == 'Expired'" class="bg-orange-500 p-1 rounded-sm text-white font-semibold text-sm">{{ isExpired(license) }} </span>
              <span v-if="isExpired(license) == 'Active'" class="bg-green-500 p-1 rounded-sm text-white font-semibold text-sm">{{ isExpired(license) }} </span>
              <span v-if="!license.approved_at" class="bg-gray-500 p-1 rounded-sm text-white font-semibold text-sm">{{ license.status }} </span>
            </div>
            <div><b class="font-semibold">Initial Issued Date:</b> {{ license.approved_at }}</div>
            <div><b class="font-semibold">Effective Date:</b> {{ license.approved_at ? license.approved_at : 'NA' }}</div>
            <div><b class="font-semibold">Affiliated Agency Name:</b> None</div>
          </div>
        </div>
      </div>
      <!-- <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold border-b">
          <th class="py-2 pl-2">Name</th>
          <th class="py-2">Date of Birth</th>
          <th class="py-2">Number</th>
          <th class="py-2">Discipline</th>
          <th class="py-2">Type</th>
          <th class="py-2">Status</th>
        </tr>
        <tr v-for="license in licenses" :key="license.id" class="hover:bg-gray-100 focus-within:bg-gray-100 text-sm">
          <td class="border-t">
            <Link class="flex items-center py-1 pl-2" :href="`/licenses/${license.id}`" tabindex="-1">
              <img v-if="license.user.photo" class="block mr-1 w-5 h-5 rounded-full" :src="license.user.photo" />
              <span>{{ license.user.first_name }} {{ license.user.last_name }}</span>
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center py-1 focus:text-indigo-500" :href="`/licenses/${license.id}`">
              {{ license.user.dob }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center py-1" :href="`/licenses/${license.id}`" tabindex="-1">
              <span class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-green-600 rounded-full">{{ license.number }}</span>
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center py-1" :href="`/licenses/${license.id}`" tabindex="-1">
              <span class="bg-gray-200 p-1 rounded-sm">{{ license.form.discipline.name }}</span>
            </Link>
          </td>

          <td class="border-t">
            <Link class="flex items-center py-1" :href="`/licenses/${license.id}`" tabindex="-1">
              <span class="bg-gray-200 p-1 rounded-sm">{{ license.form.business_type == '0' ? 'Business' : 'Individual' }}</span>
            </Link>
          </td>

          <td class="border-t">
            <Link class="flex items-center py-1" :href="`/licenses/${license.id}`" tabindex="-1">
              <span v-if="isExpired(license) == 'Expired'" class="bg-orange-500 p-1 rounded-sm text-white font-semibold text-sm">{{ isExpired(license) }} </span>
              <span v-if="isExpired(license) == 'Active'" class="bg-green-500 p-1 rounded-sm text-white font-semibold text-sm">{{ isExpired(license) }} </span>
            </Link>
          </td>

          <td class="w-px border-t">
            <Link class="flex items-center px-2" :href="`/licenses/${license.id}`" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </Link>
          </td>
        </tr>
      </table> -->
    </div>
    <div v-if="(Boolean(params.number) || Boolean(params.licensee_name)) && licenses.length == 0" class="w-full flex justify-center">
      <p class="px-2 py-1 mt-4 text-xs font-bold leading-none text-white bg-yellow-500 rounded-full">No license found...</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import { Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'

export default {
  components: {
    TextInput,
    SelectInput,
    LoadingButton,
    Link,
    Icon,
  },

  data() {
    return {
      licenses: [],
      loading: false,
      params: {
        number: '',
        licensee_name: '',
      },
      timeout: null,
    }
  },

  methods: {
    search() {
      if (this.timeout) clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        if (this.params.number.length == 0 && this.params.licensee_name.length == 0) {
          this.licenses = []
          return
        }

        this.loading = true
        axios
          .get(`/licenses/search?number=${this.params.number}&licensee_name=${this.params.licensee_name}`)
          .then((res) => (this.licenses = res.data))
          .catch((err) => err)
          .finally(() => (this.loading = false))
      }, 500)
    },

    isExpired(license) {
      if (license.approved_at) {
        const approvedDate = new Date(license.approved_at)
        const today = new Date()
        const diffTime = Math.abs(today - approvedDate)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        if (diffDays > 60) {
          return 'Expired'
        } else {
          return 'Active'
        }
      }
    },
  },
}
</script>