<template>
  <div>
    <div>
      <Head :title="editing ? 'Update' : 'Create'" />
      <h1 class="mb-8 text-xl font-bold">
        <Link class="text-indigo-400 hover:text-indigo-600" href="/questions">Questions</Link>
        <span class="text-indigo-400 font-medium">/</span>
        {{ editing ? 'Update' : 'Create' }}
      </h1>
    </div>
    <div class="bg-white rounded-md shadow overflow-hidden max-w-3xl mt-4">
      <form @submit.prevent="store">
        <div class="p-8 flex flex-wrap">
          <text-input v-model="form.title" :error="form.errors.title" class="pr-6 pb-4 w-full" label="Title" />

          <div class="grid grid-cols-2 w-full">
            <select-input v-model="form.type" :error="form.errors.type" label="Type">
              <option :value="null">Select Type</option>
              <option v-for="type in types" :key="type.id" :value="type.id">{{ type.value }}</option>
            </select-input>

            <label for="checkbox" class="flex items-center pl-4">
              <input id="checkbox" v-model="form.is_required" type="checkbox" class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 mx-1" />
              Required
            </label>
          </div>

          <div v-if="[3, 4, 5].includes(form.type)" class="w-full pt-4">
            <label>Add Options</label>
            <div class="flex space-x-2 mt-2">
              <text-input ref="choiceInput" v-model="option" class="pr-2 w-full flex-1" label="" :error="form.errors.options" @keyupenter="addChoice" />

              <button class="bg-indigo-500 text-white px-4 py-2 rounded" type="button" @click="addChoice">Add</button>
            </div>
          </div>
          <div class="flex flex-wrap mt-4 w-full">
            <span v-for="(choice, index) in form.options" :key="index" class="m-2 flex">
              <span class="bg-gray-100 p-2 rounded-l">{{ choice.text }}</span>
              <span class="text-md bg-red-100 p-2 cursor-pointer rounded-r" @click="removeChoice(choice)">&times;</span>
            </span>
          </div>

          <a v-if="form.type && form.type != 8 && formData.questions.length" href="javascript:void(0)" class="text-indigo-400 mt-8 w-full" @click="toggleSkipLogic()">{{ showSkipLogic ? 'Cancel' : 'Add' }} {{ 'Skip Logic' }}</a>
          <div v-show="showSkipLogic" class="mt-4 flex justify-between items-center w-full">
            <select-input v-model="form.related_question_id" :error="form.errors.related_question_id" class="pr-6 flex-1" label="Select Question">
              <option v-for="question in formData.questions" :key="question.id" :value="question.id">
                {{ question.title }}
              </option>
            </select-input>

            <text-input v-if="relatedQuestion && !multioptionQuestions.includes(relatedQuestion.type)" v-model="form.related_answer" class="pr-2 w-full flex-1" label="Answer" :error="form.errors.related_answer" />

            <select-input v-if="relatedQuestion && multioptionQuestions.includes(relatedQuestion.type)" v-model="form.related_answer" :error="form.errors.related_answer" class="pr-6 flex-1" label="Option">
              <option v-for="option in relatedQuestion.options" :key="option.id" :value="option.id">
                {{ option.text }}
              </option>
            </select-input>
          </div>
        </div>
      </form>

      <div class="px-8 py-4 bg-gray-50 border-t border-gray-100 flex justify-end items-center">
        <button v-if="editing" :disabled="form.processing" class="btn-indigo" type="button" @click="store">Update</button>
        <button v-else :disabled="form.processing" class="btn-indigo" type="button" @click="store">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import SearchableSelect from '@/Shared/SearchableSelect'
import TextareaInput from '../../Shared/TextareaInput'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import Button from '@/Shared/Button.vue'
import Layout from '../../Shared/Layout'
import { types } from './types'
import { Head, Link } from '@inertiajs/inertia-vue3'

export default {
  metaInfo: { title: 'Question' },
  components: {
    Button,
    TextInput,
    SelectInput,
    TextareaInput,
    SearchableSelect,
    Head,
    Link,
  },
  layout: Layout,
  props: {
    editing: {
      type: Boolean,
      default: false,
      required: true,
    },

    question: {
      type: Object,
      default: {},
      required: true,
    },
    showBreadcrumb: {
      type: Boolean,
      default: true,
    },
    categories: Array,
    formData: Object,
  },

  data() {
    return {
      option: '',
      form: this.$inertia.form(this.question),
      types: types,
      multioptionQuestions: [3, 4, 5],
      showSkipLogic: false,
      questions: [],
    }
  },

  computed: {
    relatedQuestion() {
      return this.formData.questions.find((q) => q.id == this.form.related_question_id)
    },
  },

  mounted() {
    if (this.question && this.question.id) {
      this.showSkipLogic = true
    }
  },

  methods: {
    store() {
      if (this.editing) return this.editQuestion()

      this.saveQuestion()
    },

    editQuestion() {
      this.form.put(`/questions/${this.question.id}`, {
        // onSuccess: this.onSuccess,
        // preserveState: false
      })
    },

    saveQuestion() {
      this.form.post('/questions', {
        // onSuccess: this.onSuccess,
        // preserveState: false
      })
    },

    onSuccess() {
      this.form.reset()
    },

    addChoice() {
      if (!this.option.trim()) return
      if (this.form.options.some((el) => el.text.trim() == this.option.trim())) return

      this.form.options.push({
        text: this.option,
      })

      this.option = ''
      this.$refs.choiceInput.focus()
    },

    removeChoice(choice) {
      this.form.options = this.form.options.filter((el) => el != choice)
    },

    toggleSkipLogic() {
      this.showSkipLogic = !this.showSkipLogic
      this.form.related_question_id = null
      this.form.related_answer = null
    },
  },
}
</script>

<style></style>
