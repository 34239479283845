<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="mb-2 font-semibold text-lg"><span class="fas fa-history mr-2 fa-sm" />Activity Logs</h1>
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="p-4">Causer</th>
          <th class="p-4">Action</th>
          <th class="p-4">Date</th>
          <th class="p-4">Changes</th>
        </tr>
        <tr v-for="log in getLogs(license.logs)" :key="log.id" class="hover:bg-gray-100 focus-within:bg-gray-100 text-sm">
          <td class="border-t">
            <span v-if="log.causer" class="flex items-center px-4 py-2" tabindex="-1"> {{ log.causer.last_name }} </span>
            <span v-else>Seeder</span>
          </td>
          <td class="border-t">
            <span class="flex items-center px-4 py-2" tabindex="-1">
              <span class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-green-600 rounded-sm">{{ log.description }}</span>
            </span>
          </td>
          <td class="border-t">
            <span class="flex items-center px-4 py-2" tabindex="-1">
              {{ log.created_at.split('T')[0] }}
            </span>
          </td>
          <td class="border-t">
            <span class="flex items-center px-4 py-2" tabindex="-1">
              <span>{{ log.properties.old.status }} -> {{ log.properties.attributes.status }}</span>
            </span>
          </td>
        </tr>
        <tr v-if="license.logs && license.logs.length === 0">
          <td class="px-4 py-4 border-t" colspan="4">No Activity Log Found!</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import mapValues from 'lodash/mapValues'

export default {
  metaInfo: { title: 'Activity Logs' },

  layout: Layout,
  props: {
    license: Object,
    withoutHead: Boolean,
  },
  data() {
    return {
      downloading: false,
      form: {
        loggedModel: 'License',
        subject_id: this.license.id,
      },
    }
  },

  mounted() {},

  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },

    getLogs(logs) {
      return logs.filter((log) => log.properties.attributes.status && log.properties.old)
    },
  },
}
</script>
