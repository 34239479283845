<template>
  <div>
    <Head :title="`${form.name}`" />
    <h1 class="mb-8 text-xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/roles">Roles</Link>
      <span class="text-indigo-400 font-medium">/</span> {{ form.name }}
    </h1>
  </div>
  <trashed-message v-if="role.deleted_at" class="mb-6" @restore="restore">Role is deleted.</trashed-message>
  <div class="bg-white rounded-md shadow overflow-hidden max-w-3xl">
    <form @submit.prevent="update">
      <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
        <text-input v-model="form.name" :error="form.errors.name" class="pr-6 pb-8 w-full lg:w-1/2" label="Name" />
      </div>
      <div class="px-8 py-4 bg-gray-50 border-t border-gray-100 flex items-center">
        <button class="text-red-600 hover:underline mr-4" tabindex="-1" type="button" @click="destroy">Delete Role</button>
        <loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit">Update Role</loading-button>
      </div>
    </form>
  </div>

  <role-permissions :role="role" :permissions="permissions" />
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'
import RolePermissions from './RolePermissions'

export default {
  metaInfo() {
    return {
      title: `${this.form.name}`,
    }
  },
  components: {
    LoadingButton,
    TextInput,
    TrashedMessage,
    Head,
    Link,
    RolePermissions,
  },
  layout: Layout,
  props: {
    role: Object,
    permissions: Object,
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        _method: 'put',
        name: this.role.name,
      }),
    }
  },
  methods: {
    update() {
      this.form.put(`/roles/${this.role.id}`)
    },
    destroy() {
      if (confirm('Are you sure you want to delete this role?')) {
        this.$inertia.delete(`/roles/${this.role.id}`)
      }
    },
    restore() {
      if (confirm('Are you sure you want to restore this role?')) {
        this.$inertia.put(this.route('roles.restore', this.role.id))
      }
    },
  },
}
</script>
