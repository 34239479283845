export const stepTemplate = {
    label: "",
    description: "",
    if: { name: null, equalsTo: null },
    elements: [],
}
export const stepFormType = {
    type: "steps",
    currentStep: 1,
    stepsCount: 2,
    steps: [
        {
            label: "",
            description: "",
            if: { name: null, equalsTo: null },
            elements: [],
        },
        {
            label: "",
            description: "",
            if: { name: null, equalsTo: null },
            elements: [],
        },
    ],

    get currentStepIndex() {
        return this.currentStep - 1;
    },
    get totalSteps() {
        return this.steps.length;
    },
    get isFirstStep() {
        return this.currentStep === 1;
    },
    get isLastStep() {
        return this.currentStep === this.totalSteps;
    },
    get getAllElements() {
        return [].concat(this.steps.map(s => s.elements))
    },
    onStepClick(step) { this.currentStep = step },
    previousStep() {
        if (this.isFirstStep) return;
        this.currentStep--;
    },
    nextStep() {
        if (this.isLastStep) return;
        this.currentStep++;
    }
}
export const elements = [
    {
        label: "Text input",
        description: "Single line text input",
        icon: ["fas", "font"],
        cols: 12,
        schema: {
            if: { name: null, equalsTo: null },
            required: false,
            name: "",
            type: "text",
            placeholder: "",
            description: "",
            cols: 12,
            label: "Text",
        },
    },
    {
        label: "Number input",
        description: "Input field that only allows numbers",
        icon: ["fas", "hashtag"],
        cols: 12,
        schema: {
            if: { name: null, equalsTo: null },
            required: false,
            name: "",
            placeholder: "",
            description: "",
            cols: 12,
            type: "number",
            rules: ["nullable", "numeric"],
            autocomplete: "off",
            label: "Number",
        },
    },
    {
        label: "Email address",
        description: "Text field that expects an email",
        icon: ["fas", "at"],
        cols: 12,
        schema: {
            if: { name: null, equalsTo: null },
            required: false,
            name: "",
            type: "text",
            placeholder: "",
            description: "",
            cols: 12,
            type: "email",
            rules: ["nullable", "email"],
            label: "Email",
        },
    },
    {
        label: "Phone number",
        description: "Text field that expects a phone number",
        icon: ["fas", "phone"],
        cols: 12,
        schema: {
            if: { name: null, equalsTo: null },
            required: false,
            name: "",
            type: "tel",
            placeholder: "",
            description: "",
            cols: 12,
            type: "tel",
            label: "Phone",
        },
    },
    {
        label: "Password",
        description: "Text field that expects a password",
        icon: ["fas", "lock"],
        cols: 12,
        schema: {
            if: { name: null, equalsTo: null },
            required: false,
            name: "",
            type: "password",
            placeholder: "",
            description: "",
            cols: 12,
            label: "Password",
        },
    },
    {
        label: "URL",
        description: "Text field that expects an URL",
        icon: ["fas", "link"],
        cols: 12,
        schema: {
            if: { name: null, equalsTo: null },
            required: false,
            name: "",
            type: "text",
            description: "",
            cols: 12,
            type: "url",
            rules: ["nullable", "url"],
            placeholder: "eg. http(s)://domain.com",
            floating: false,
            label: "URL",
        },
    },
    {
        label: "Textarea",
        description: "Single line or multiline text area",
        icon: ["fas", "align-left"],
        cols: 12,
        schema: {
            if: { name: null, equalsTo: null },
            required: false,
            name: "",
            type: "textarea",
            placeholder: "",
            description: "",
            cols: 12,
            label: "Textarea",
        },
    },
    {
        label: "Checkbox",
        description: "Plain checkbox input",
        icon: ["fas", "check-square"],
        cols: 12,
        schema: {
            if: { name: null, equalsTo: null },
            required: false,
            name: "",
            type: "checkbox",
            placeholder: "",
            description: "",
            cols: 12,
            text: "Checkbox",
            label: "Checkbox",
        },
    },
    {
        label: "Radio",
        description: "Plain radio input",
        icon: ["fas", "dot-circle"],
        cols: 12,
        schema: {
            if: { name: null, equalsTo: null },
            required: false,
            name: "",
            type: "radio",
            value: "radio",
            placeholder: "",
            description: "",
            cols: 12,
            text: "Radio",
            label: "Radio",
        },
    },
    {
        label: "Select",
        description: "Select input",
        icon: ["fas", "caret-square-down"],
        cols: 12,
        schema: {
            if: { name: null, equalsTo: null },
            required: false,
            name: "",
            type: "select",
            placeholder: "",
            description: "",
            cols: 12,
            label: "Select",
            autocomplete: "off",
        },
    },
    {
        label: "Multiselect",
        description: "Multiselect input",
        icon: ["fas", "plus-square"],
        cols: 12,
        schema: {
            if: { name: null, equalsTo: null },
            required: false,
            name: "",
            placeholder: "",
            description: "",
            cols: 12,
            search: true,
            label: "Multiselect",
            type: "select",
            multiple: true,
            autocomplete: "off",
        },
    },
    {
        label: "Date",
        description: "Date input",
        icon: ["fas", "calendar"],
        cols: 12,
        schema: {
            if: { name: null, equalsTo: null },
            required: false,
            name: "",
            type: "date",
            placeholder: "",
            description: "",
            cols: 12,
            label: "Date",
        },
    },
    {
        label: "Datetime",
        description: "Date & time input",
        icon: ["fas", "calendar"],
        cols: 12,
        schema: {
            if: { name: null, equalsTo: null },
            required: false,
            name: "",
            type: "datetime-local",
            placeholder: "",
            description: "",
            cols: 12,
            label: "Datetime",
            time: true,
        },
    },
    {
        label: "Time",
        description: "Time input",
        icon: ["fas", "clock"],
        cols: 12,
        schema: {
            if: { name: null, equalsTo: null },
            required: false,
            name: "",
            type: "time",
            placeholder: "",
            description: "",
            cols: 12,
            label: "Time",
            time: true,
            date: false,
        },
    },
    {
        label: "File upload",
        description: "File upload input",
        icon: ["fas", "file"],
        cols: 12,
        schema: {
            if: { name: null, equalsTo: null },
            required: false,
            name: "",
            type: "file",
            placeholder: "",
            description: "",
            cols: 12,
            label: "File",
        },
    },
    {
        label: "Multi-file upload",
        description: "Multi-file upload input",
        icon: ["fas", "copy"],
        cols: 12,
        schema: {
            if: { name: null, equalsTo: null },
            required: false,
            name: "",
            type: "file",
            multiple: true,
            placeholder: "",
            description: "",
            cols: 12,
            label: "Multi-file",
        },
    },
    {
        label: "Hidden input",
        description: "Single line or multiline text area",
        icon: ["fas", "eye-slash"],
        cols: 12,
        schema: {
            if: { name: null, equalsTo: null },
            required: false,
            name: "",
            type: "hidden",
            placeholder: "",
            description: "",
            cols: 12,
        },
    },
];

export const selectInputTypes = ["select", "radio"];
export const conditionInputTypes = ["radio", "checkbox"];
export const fileInputTypes = ["file", "image"];
export const textareaTypes = ["textarea"];
export const inputTypes = [
    "color",
    "date",
    "datetime-local",
    "email",
    "hidden",
    "month",
    "number",
    "password",
    "range",
    "tel",
    "text",
    "time",
    "url",
    "week",
];

export const allInputTypes = [
    ...inputTypes,
    ...textareaTypes,
    ...fileInputTypes,
    ...conditionInputTypes,
    ...selectInputTypes,
];
