<template>
  <div class="p-8 border-4 border-gray-700 border-dashed flex justify-center flex-col items-center gap-y-2 text-lg h-full">
    <div>
      <img class="w-36" src="/images/dlcp_logo.png" alt="" />
    </div>
    <div class="text-2xl font-bold uppercase mt-8">Certificate of Ownership</div>
    <div class="mt-8">This is to certify that</div>
    <div class="mt-6 border-b border-dotted border-gray-700 text-center font-semibold w-1/2">{{ license.user.first_name }}</div>
    <div class="mt-4">is the legal owner of a license with the below details</div>
    <div class="mt-4"><span class="font-semibold">License Number:</span> {{ license.number }}</div>
    <div><span class="font-semibold">Issued Date:</span> {{ new Date().toLocaleDateString() }}</div>
    <div><span class="font-semibold">Expiry Date:</span> {{ new Date().toLocaleDateString() }}</div>
    <div class="flex justify-end mt-8 b w-full">Signed</div>
    <div class="flex self-end justify-end mt-8 b w-1/2 border-b border-dotted border-gray-700"></div>
  </div>
</template>

<script>
export default {
  props: ['license'],
}
</script>

<style>
</style>