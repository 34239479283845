<template>
  <div>
    <h3 class="p-4 font-semibold text-medium bg-gray-200">{{ title }}</h3>
    <hr />
    <!-- <label class="font-semibold"></label> -->
    <div class="grid grid-cols-2 gap-4 p-4">
      <text-input v-model="form.name" :error="errors[field + '.name']" class="w-full" label="Name" />
      <text-input v-model="form.email" :error="errors[field + '.email']" class="w-full" label="Email" />
      <text-input v-model="form.phone" :error="errors[field + '.phone']" class="w-full" label="Phone" />
      <text-input v-model="form.address_line_1" :error="errors[field + '.address_line_1']" class="w-full" label="Address" />
      <text-input v-model="form.city" :error="errors[field + '.city']" class="w-full" label="City" />
      <text-input v-model="form.state" :error="errors[field + '.state']" class="w-full" label="Province/State" />
      <text-input v-model="form.zip_code" :error="errors[field + '.zip_code']" class="w-full" label="Postal code" />
    </div>
  </div>
</template>

<script>
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'

export default {
  components: {
    TextInput,
    SelectInput,
  },

  props: {
    form: Object,
    title: String,
    errors: Object,
    field: String,
  },
}
</script>

<style>
</style>