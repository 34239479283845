<template>
  <div>
    <Head title="Questions" />
    <div class="flex justify-between items-center">
      <h1 class="mb-8 text-2xl font-bold">Questions</h1>
      <div class="flex items-center justify-between mb-6">
        <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
          <label class="block text-gray-700">Trashed:</label>
          <select v-model="form.trashed" class="form-select mt-1 w-full">
            <option :value="null" />
            <option value="with">With Trashed</option>
            <option value="only">Only Trashed</option>
          </select>
        </search-filter>
        <Link class="btn-indigo" href="/questions/create">
          <span>Create</span>
          <span class="hidden md:inline">&nbsp;Question</span>
        </Link>
      </div>
    </div>

    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="p-4">Title</th>
          <th class="p-4">Type</th>
        </tr>
        <tr v-for="question in questions.data" :key="question.id" class="hover:bg-gray-100 focus-within:bg-gray-100 text-sm">
          <td class="border-t">
            <Link class="flex items-center px-4 py-2 focus:text-indigo-500" :href="`/questions/${question.id}/edit`">
              {{ question.title }}
              <icon v-if="question.deleted_at" name="trash" class="flex-shrink-0 ml-2 w-3 h-3 fill-gray-400" />
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-4 py-2" :href="`/questions/${question.id}/edit`" tabindex="-1">
              <div v-if="question.title">
                {{ questionType(question.type) }}
              </div>
            </Link>
          </td>
          <td class="w-px border-t">
            <Link class="flex items-center px-4" :href="`/questions/${question.id}/edit`" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </Link>
          </td>
        </tr>
        <tr v-if="questions.data.length === 0">
          <td class="px-4 py-2 border-t text-sm" colspan="4">No questions found.</td>
        </tr>
      </table>
    </div>
    <pagination class="mt-6" :links="questions.links" />
  </div>
</template>
  
<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import SearchFilter from '@/Shared/SearchFilter'
import { types } from './types'

export default {
  components: {
    Head,
    Icon,
    Link,
    Pagination,
    SearchFilter,
  },
  layout: Layout,
  props: {
    filters: Object,
    questions: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        trashed: this.filters.trashed,
      },
      types: types,
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function () {
        this.$inertia.get('/questions', pickBy(this.form), { preserveState: true })
      }, 150),
    },
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },

    questionType(id) {
      return this.types.find((t) => t.id == id).value
    },
  },
}
</script>
  