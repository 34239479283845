<template>
  <div>
    <Head title="License Data Change" />
    <div class="flex justify-between items-center">
      <h1 class="mb-8 text-2xl font-bold">License Data Change Requests</h1>
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold border-b">
          <th class="p-4">Name</th>
          <th class="p-4">Number</th>
          <th class="p-4">Discipline</th>
          <th class="p-4">Data to Change</th>
          <th class="p-4">Actions</th>
        </tr>
        <tr v-for="license in licenses.data" :key="license.id" class="text-sm">
          <td class="border-t">
            <div class="flex items-center px-4 py-2" tabindex="-1">
              <img v-if="license.user.photo" class="block mr-1 w-5 h-5 rounded-full" :src="license.user.photo" />
              {{ license.user.first_name }} {{ license.user.last_name }}
            </div>
          </td>

          <td class="border-t">
            <div class="flex items-center px-4 py-2" tabindex="-1">
              <span class="bg-green-500 p-1 rounded-sm text-white font-semibold text-sm">{{ license.number }} </span>
            </div>
          </td>
          <td class="border-t">
            <div class="flex items-center px-4 py-2" tabindex="-1">
              {{ license.form.discipline.name }}
            </div>
          </td>
          <td class="border-t">
            <div class="flex items-center px-4 py-2" tabindex="-1">
              {{ license.pending_form_data }}
            </div>
          </td>

          <td class="border-t">
            <div class="flex flex-col items-left px-4 py-2" tabindex="-1">
              <Link class="mb-1 hover:text-green-600"><span class="fas fa-eye fa-sm mr-2" />View License</Link>
              <Link class="mb-1 hover:text-green-600"><span class="fas fa-check fa-sm mr-2" /> Approve Changes</Link>
              <Link class="mb-1 hover:text-green-600"><span class="fas fa-ban fa-sm mr-2" /> Reject Changes</Link>
            </div>
          </td>
        </tr>
        <tr v-if="licenses.data.length === 0">
          <td class="px-4 py-2 border-t" colspan="4">No license data change requests found.</td>
        </tr>
      </table>
    </div>
    <pagination class="mt-6" :links="licenses.links" />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import SearchFilter from '@/Shared/SearchFilter'
import Icon from '@/Shared/Icon'
import Pagination from '@/Shared/Pagination'
import LoadingButton from '@/Shared/LoadingButton'
import { userCan } from '@/utils/Utils'

export default {
  components: {
    Head,
    Link,
    Icon,
    SearchFilter,
    Pagination,
    LoadingButton,
  },
  layout: Layout,
  props: {
    licenses: Object,
    filters: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
      },
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function () {
        this.$inertia.get('/licenses', this.form, { preserveState: true })
      }, 150),
    },
  },
  methods: {
    userCan,
    reset() {
      this.form = mapValues(this.form, () => null)
    },
  },
}
</script>
