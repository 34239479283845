<template>
  <div>
    <Head :title="`${form.first_name} ${form.last_name}`" />
    <div class="flex justify-between mb-8">
      <div class="flex">
        <h1 class="text-xl font-bold">
          <!-- <Link class="text-indigo-400 hover:text-indigo-600" href="/users">Users {{ user.roles.name }}</Link>
          <span class="text-indigo-400 font-medium">/</span> -->
          {{ form.first_name }} {{ form.last_name }}
        </h1>
        <img v-if="user.photo" class="block ml-4 w-8 h-8 rounded-full" :src="user.photo" />
      </div>
    </div>
    <trashed-message v-if="user.deleted_at" class="mb-6" @restore="restore"> This user has been deleted. </trashed-message>
    <div class="flex flex-col">
      <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden mb-4">
        <form @submit.prevent="update">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.first_name" :error="form.errors.first_name" class="pb-8 pr-6 w-full lg:w-1/2" label="First name" />
            <text-input v-model="form.last_name" :error="form.errors.last_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Last name" />
            <text-input v-model="form.email" :error="form.errors.email" class="pb-8 pr-6 w-full lg:w-1/2" label="Email" />
            <text-input v-model="form.password" :error="form.errors.password" class="pb-8 pr-6 w-full lg:w-1/2" type="password" autocomplete="new-password" label="Password" />
            <text-input v-model="form.dob" :error="form.errors.dob" class="pb-8 pr-6 w-full lg:w-1/2" type="date" label="Date of birth" />
            <select-input v-model="form.role" class="pb-8 pr-6 w-full lg:w-1/2" label="Role">
              <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.name }}</option>
            </select-input>
            <file-input v-model="form.photo" :error="form.errors.photo" class="pb-8 pr-6 w-full lg:w-1/2" type="file" accept="image/*" label="Photo" />
          </div>
          <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
            <button v-if="!user.deleted_at" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy">Delete User</button>
            <loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit">Update User</loading-button>
          </div>
        </form>
      </div>
      <div v-if="form.type == 1" class="max-w-3xl bg-white rounded-md shadow overflow-hidden flex flex-col justify-between">
        <association-list />
      </div>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import FileInput from '@/Shared/FileInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'
import AssociationList from '@/Shared/Association/List'

export default {
  components: {
    FileInput,
    Head,
    Link,
    LoadingButton,
    SelectInput,
    TextInput,
    TrashedMessage,
    AssociationList,
  },
  layout: Layout,
  props: {
    user: Object,
    roles: Array,
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        _method: 'put',
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        type: this.user.type,
        password: '',
        role: this.user.roles.length > 0 ? this.user.roles[0].id : null,
        photo: null,
        dob: this.user.dob,
      }),
    }
  },
  methods: {
    update() {
      this.form.post(`/users/${this.user.id}`, {
        onSuccess: () => this.form.reset('password', 'photo'),
      })
    },
    destroy() {
      if (confirm('Are you sure you want to delete this user?')) {
        this.$inertia.delete(`/users/${this.user.id}`)
      }
    },
    restore() {
      if (confirm('Are you sure you want to restore this user?')) {
        this.$inertia.put(`/users/${this.user.id}/restore`)
      }
    },
  },
}
</script>
