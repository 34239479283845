<template>
  <div>
    <Head title="License Form" />
    <div class="min-w-4xl overflow-hidden">
      <form @submit.prevent="store">
        <div class="flex flex-col gap-5">
          <div class="p-4 bg-white rounded-md shadow grid grid-cols-2">
            <div>
              <label class="font-semibold">What type of License are you applying?</label>
              <div class="flex gap-4 mt-4">
                <label for="business"> <input type="radio" value="0" v-model="form.business_type" id="business" name="business_type" /> Business </label>
                <label for="individual"> <input type="radio" value="1" v-model="form.business_type" id="individual" name="business_type" /> Individual </label>
              </div>
            </div>
            <div>
              <label class="font-semibold">Please select your discipline below.</label>
              <div class="flex flex-col gap-4 mt-4">
                <select-input v-model="form.discipline_id">
                  <option value="">Select discipline</option>
                  <option :value="discipline.id" v-for="discipline in filteredDisciplines" :key="discipline.id">{{ discipline.name }}</option>
                </select-input>
                <span class="text-red-500 text-sm font-semibold">{{ form.errors.discipline_id }}</span>
              </div>
            </div>
          </div>

          <div class="p-4 bg-white rounded-md shadow">
            <label class="font-semibold">Which type of License?</label>
            <div class="flex flex-col gap-4 mt-4">
              <select-input v-model="form.license_type_id" @change="onLicenseTypeSelected">
                <option value="">Select one</option>
                <option :value="licenseType.id" v-for="licenseType in licenseTypes" :key="licenseType.name">{{ licenseType.name }}</option>
              </select-input>
              <span class="text-red-500 text-sm font-semibold">{{ form.errors.license_type_id }}</span>
            </div>
          </div>

          <div class="p-4 bg-white rounded-md shadow grid grid-cols-2 gap-4">
            <text-input v-model="form.social_security_number" label="Social Security Number" :error="form.errors.social_security_number" />
            <text-input v-model="form.fein_number" label="FEIN Number" :error="form.errors.fein_number" />
          </div>

          <div>
            <label class="font-semibold">Business Details</label>
            <div class="p-4 bg-white rounded-md shadow grid grid-cols-2 gap-4 mt-2">
              <text-input v-model="form.name" :error="form.errors.name" class="w-full" label="Name" />
              <text-input v-model="form.email" :error="form.errors.email" class="w-full" label="Email" />
              <text-input v-model="form.phone" :error="form.errors.phone" class="w-full" label="Phone" />
              <text-input v-model="form.address" :error="form.errors.address" class="w-full" label="Address" />
              <text-input v-model="form.city" :error="form.errors.city" class="w-full" label="City" />
              <text-input v-model="form.region" :error="form.errors.region" class="w-full" label="Province/State" />
              <select-input v-model="form.country" :error="form.errors.country" class="w-full" label="Country">
                <option :value="null" />
                <option value="CA">Canada</option>
                <option value="US">United States</option>
              </select-input>
              <text-input v-model="form.postal_code" :error="form.errors.postal_code" class="w-full" label="Postal code" />
            </div>
          </div>

          <div class="grid grid-cols-2 gap-4">
            <div v-for="(question, index) in form.questions" v-show="showRelatedQuestion(question)" :key="question.id" class="w-full p-4 bg-white rounded-md shadow mt-2">
              <div v-if="question.type == 1 && showRelatedQuestion(question)" class="w-full">
                <text-input v-model="question.answer" :description="question.description" :error="getError(index)" :label="question.title" />
              </div>
              <div v-if="question.type == 2 && showRelatedQuestion(question)" class="w-full">
                <text-input v-model="question.answer" :description="question.description" :error="getError(index)" :label="question.title" type="number" />
              </div>

              <div v-if="question.type == 3 && showRelatedQuestion(question)" class="w-full">
                <select-input v-model="question.answer" :error="getError(index)" :label="question.title">
                  <option v-for="option in question.options" :key="option.id" :value="option.id">
                    {{ option.text }}
                  </option>
                </select-input>
              </div>

              <div v-if="question.type == 4 && showRelatedQuestion(question)" class="w-full">
                <div class="mb-2">{{ question.title }}</div>
                <div class="flex flex-wrap">
                  <label v-for="option in question.options" :key="option.id" :for="option.id" class="flex items-center mr-4">
                    <input :id="option.id" v-model="question.answer" type="checkbox" :value="option.id" class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 mx-1" />
                    {{ option.text }}
                  </label>
                </div>
                <div class="form-error">{{ getError(index) }}</div>
              </div>

              <div v-if="question.type == 5 && showRelatedQuestion(question)" class="w-full">
                <div class="mb-2">{{ question.title }}</div>
                <div class="flex flex-wrap">
                  <label v-for="option in question.options" :key="option.id" :for="option.id" class="flex items-center mr-4">
                    <input :id="option.id" v-model="question.answer" type="radio" :value="option.id" class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 mx-1" />
                    {{ option.text }}
                  </label>
                </div>
                <div class="form-error">{{ getError(index) }}</div>
              </div>

              <div v-if="question.type == 6 && showRelatedQuestion(question)" class="w-full">
                <div class="mb-2">{{ question.title }}</div>
                <div v-if="!question.attachment" class="flex items-center bg-grey-lighter">
                  <label class="w-full flex items-center gap-2 px-4 py-2 bg-indigo-500 text-white rounded cursor-pointer">
                    <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                    </svg>
                    <span class="text-base leading-normal mx-4"> Select File</span>
                    <input type="file" class="hidden" @change="upload($event, question)" />
                  </label>
                </div>
                <div v-if="question.attachment" class="flex justify-between w-full px-4 py-4 border border-indigo-200 bg-white rounded">
                  <span class="mr-4">{{ question.attachment.original_name }}</span>
                  <a class="font-bold" href="javascript:void(0)" @click="removeAttachment(question)">&times;</a>
                </div>
                <div class="form-error">{{ getError(index) }}</div>
              </div>

              <div v-if="question.type == 7 && showRelatedQuestion(question)" class="w-full">
                <text-input v-model="question.answer" :description="question.description" :error="getError(index)" :label="question.title" type="date" />
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
          <loading-button :loading="form.processing" class="btn-indigo" type="submit">Submit Application</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>
  
<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import axios from 'axios'

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    SelectInput,
    TextInput,
  },
  layout: Layout,
  remember: 'form',
  props: ['disciplines'],

  data() {
    return {
      errors: [],
      form: this.$inertia.form({
        business_type: 0,
        discipline_id: null,
        license_type_id: null,
        social_security_number: null,
        fein_number: null,
        dob: null,
        questions: [],
      }),
    }
  },

  computed: {
    filteredDisciplines() {
      return this.disciplines.filter((d) => d.business_type == this.form.business_type)
    },

    licenseTypes() {
      if (!this.form.discipline_id) {
        return []
      }

      return this.disciplines.find((d) => d.id == this.form.discipline_id).license_types
    },
  },

  mounted() {
    console.log(this.form.questions)
  },

  methods: {
    onLicenseTypeSelected(event) {
      let questions = this.licenseTypes.find((lt) => lt.id == event.target.value).questions.map((q) => (q.type == 4 ? { ...q, answer: [] } : q))

      this.form.questions = [...questions]
    },

    store() {
      this.form.post('/licenses', {
        preserveState: true,
      })
    },

    upload(event, question) {
      question.uploadPercentage = 0

      let formData = new FormData()
      formData.append('file', event.target.files[0])
      formData.append('question_id', question.id)

      axios
        .post('/attachments', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: function (progressEvent) {
            question.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
          }.bind(this),
        })
        .then((response) => {
          this.form.questions = this.form.questions.map((q) => (q.id == question.id ? { ...question, answer: response.data.id, attachment: response.data } : q))
        })
        .catch((error) => {})
    },

    removeAttachment(question) {
      this.form.questions = this.form.questions.map((q) => (q.id == question.id ? { ...question, answer: null, attachment: null } : q))
      axios
        .delete(`/attachments/${question.attachment.id}`)
        .then((res) => res)
        .catch((err) => err)
    },

    getError(index, key) {
      if (this.form.errors['questions.' + index + '.answer']) {
        return this.form.errors['questions.' + index + '.answer']
      }

      if (key && this.form.errors[key]) {
        return this.form.errors[key]
      }

      return null
    },

    showRelatedQuestion(question) {
      if (!question.related_question_id) return true

      let relatedQuestion = this.form.questions.find((q) => q.id == question.related_question_id)

      return relatedQuestion && relatedQuestion.answer == question.related_answer
    },
  },
}
</script>
  