
<template>
  <Head title="Sign Up" />
  <div class="p-6 bg-blue-800 min-h-screen flex flex-col justify-center items-center">
    <div class="grid grid-cols-2 w-full max-w-4xl bg-white rounded-lg shadow-xl overflow-hidden">
      <div class="flex flex-col justify-center flex-wrap content-center">
        <logo class="block mx-auto max-w-xs fill-white h-50 w-64" />
      </div>

      <form class="mt-8 bg-white rounded-lg shadow-xl overflow-hidden" @submit.prevent="register">
        <div class="px-10 py-12">
          <h1 class="text-center text-2xl font-bold">Create Account</h1>
          <div class="mt-6 mx-auto w-24 border-b-2" />
          <flash-messages />
          <text-input v-model="form.first_name" :error="form.errors.first_name" class="mt-6" label="First name" autofocus />
          <text-input v-model="form.last_name" :error="form.errors.last_name" class="mt-6" label="Last name" />
          <text-input v-model="form.email" :error="form.errors.email" class="mt-6" label="Email" type="email" autocapitalize="off" />
          <text-input v-model="form.password" :error="form.errors.password" class="mt-6" label="Password" type="password" />
          <text-input v-model="form.dob" :error="form.errors.dob" class="mt-6" type="date" label="Date of birth" />
          <file-input v-model="form.photo" :error="form.errors.photo" class="mt-6" type="file" accept="image/*" label="Photo" />
        </div>
        <div class="flex px-10 py-4 bg-gray-100 border-t border-gray-100 items-center justify-between">
          <a class="text-sm text-indigo-600 hover:text-indigo-800" href="/login">Login</a>
          <loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit"><span class="fas fa-save fa-sm mr-2" />Register</loading-button>
        </div>
      </form>
    </div>
    <div class="pt-10 flex text-sm">
      <p class="text-gray-400">Developed by</p>
      <a href="https://consulatesys.com/" class="text-bold ml-1 hover:underline">Consulate SYS</a>
    </div>
  </div>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import Logo from '@/Shared/Logo'
import TextInput from '@/Shared/TextInput'
import LoadingButton from '@/Shared/LoadingButton'
import FlashMessages from '@/Shared/FlashMessages'
import FileInput from '@/Shared/FileInput'

export default {
  components: {
    Head,
    LoadingButton,
    Logo,
    TextInput,
    FlashMessages,
    FileInput,
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        photo: null,
        dob: null,
      }),
    }
  },
  methods: {
    register() {
      this.form.post('/register')
    },
  },
}
</script>
