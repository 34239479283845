<template>
  <div>
    <div>
      <h1 class="mb-4 text-xl font-bold mt-4">Permissions</h1>
    </div>

    <form @submit.prevent="update">
      <div class="bg-white rounded-md shadow overflow-hidden max-w-3xl">
        <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
          <label v-for="permission in permissions" :key="permission.id" class="inline-flex items-center mb-5 w-1/4">
            <input v-model="form.permissions" type="checkbox" class="form-checkbox h-5 w-5" :value="permission.id" />
            <span class="ml-2 capitalize">{{ permission.name }}</span>
          </label>
        </div>
        <div class="px-8 py-4 bg-gray-50 border-t border-gray-100 flex justify-end items-center">
          <loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit">Update Permissions</loading-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'

export default {
  components: {
    LoadingButton,
  },

  layout: Layout,

  props: {
    role: Object,
    permissions: Array,
  },

  remember: 'form',

  data() {
    return {
      form: this.$inertia.form({
        _method: 'put',
        permissions: this.role.permissions.map((permission) => {
          return permission.id
        }),
      }),
    }
  },
  methods: {
    update() {
      this.form.put(`/roles/${this.role.id}/permissions`)
    },
  },
}
</script>
